::-webkit-input-placeholder {
  opacity: 0.5;
}

::-moz-placeholder {
  opacity: 0.5;
}

:-ms-input-placeholder {
  opacity: 0.5;
}

input:-moz-placeholder {
  opacity: 0.5;
}

.wh-form__fieldgroup:not(.wh-form__fieldgroup--hidden) {
  display: flex;
}

.wh-form__fieldgroup {
  align-items: baseline;
}

.wh-form__label, .wh-form__optiondata,
.wh-form__optionlabel,
.wh-form__subfieldlabel {
  display: inline-block;
}

.wh-form__label {
  flex-shrink: 0;
}

.wh-form__fields {
  flex: 1 0 0px;
}

.wh-form__fieldline {
  width: 100%;
  align-items: baseline;
  flex-wrap: wrap;
}

.wh-form__fieldline:not(.wh-form__fieldline--hidden) {
  display: inline-flex;
}

.wh-form__fieldline--nowrap:not(.wh-form__fieldline--hasinfotext) {
  flex-wrap: nowrap;
}

.wh-form__fieldgroup--horizontal .wh-form__fields {
  display: flex;
  flex-wrap: wrap;
}
.wh-form__fieldgroup--horizontal .wh-form__fieldline {
  flex: none;
  width: auto;
  max-width: 100%;
}

.wh-form__infotextwrapper {
  flex: 0 0 100%;
}

.wh-form__optiondata {
  display: inline-flex;
  width: 100%;
  align-items: baseline;
  flex-grow: 1;
  flex-wrap: wrap;
}
.wh-form__optiondata--vertical {
  width: 0;
}

.wh-form__subfield {
  display: flex;
  flex-grow: 1;
  align-items: baseline;
}

.wh-form__fieldline > input[type=checkbox],
.wh-form__fieldline > input[type=radio] {
  flex-shrink: 0;
}

.wh-form__fieldline > textarea {
  resize: vertical;
}

/* webpack and webforms */
.wh-styledinput.wh-form .wh-form__fieldgroup--richtext:first-child {
  border-bottom: 1px solid #6cbc21;
  margin-bottom: 20px;
}
.wh-styledinput.wh-form a {
  color: inherit;
}
.wh-styledinput.wh-form .wh-form__richtext > *:last-child {
  margin-bottom: 0;
}
.wh-styledinput.wh-form .wh-form__page * + .wh-form__fieldgroup--richtext {
  margin-top: 20px;
}
.wh-styledinput.wh-form .wh-form__fieldgroup--richtext .wh-form__fields {
  max-width: 100%;
  flex-basis: 0px;
}
.wh-styledinput.wh-form .wh-form__fieldgroup--richtext .wh-form__fields .wh-form__fieldline {
  display: block;
}
.wh-styledinput.wh-form .wh-form__fieldgroup--customercode input {
  max-width: 120px;
}
.wh-styledinput.wh-form .wh-form__fieldgroup {
  padding-bottom: 20px;
}
.wh-styledinput.wh-form .wh-form__fieldgroup--checkbox {
  padding-left: 225px;
}
.wh-styledinput.wh-form .wh-form__fieldgroup--checkbox .wh-form__fields {
  max-width: 100%;
}
.wh-styledinput.wh-form .wh-form__uploadfield {
  width: 100%;
}
.wh-styledinput.wh-form .wh-form__uploadfieldfilename {
  width: 100%;
}
.wh-styledinput.wh-form .wh-form__uploadfieldselect {
  margin-top: 5px;
}
.wh-styledinput.wh-form .wh-form__fieldgroup > .wh-form__label {
  text-align: right;
  padding-right: 32px;
  width: 225px;
}
.wh-styledinput.wh-form .wh-form__fields {
  max-width: calc(100% - 225px);
}
.wh-styledinput.wh-form .wh-form__fieldgroup--textarea > label,
.wh-styledinput.wh-form .wh-form__fieldgroup--captcha > label {
  align-self: flex-start; /* fix for safari */
  padding-top: 5px;
}
.wh-styledinput.wh-form .wh-form__fieldgroup--required > .wh-form__label:after {
  content: "*";
}
.wh-styledinput.wh-form .wh-form__fieldline {
  position: relative;
}
.wh-styledinput.wh-form .wh-form__fieldline .wh-form__prefix {
  padding-right: 10px;
}
.wh-styledinput.wh-form .wh-form__fieldline .wh-form__suffix {
  padding-left: 10px;
}
.wh-styledinput.wh-form input[type=radio],
.wh-styledinput.wh-form input[type=checkbox] {
  display: none;
}
.wh-styledinput.wh-form input[type=radio] + label,
.wh-styledinput.wh-form input[type=checkbox] + label {
  vertical-align: top;
  cursor: pointer;
  position: relative;
  top: 2px;
}
.wh-styledinput.wh-form input[type=radio] + label:before,
.wh-styledinput.wh-form input[type=checkbox] + label:before {
  content: "";
  height: 20px;
  width: 20px;
  border: 1px solid #59280b;
  background-color: #fff;
  border-radius: 0;
  display: inline-block;
  outline: none;
  position: relative;
  top: 4px;
}
.wh-styledinput.wh-form input[type=radio] + label:before {
  border-radius: 100%;
}
.wh-styledinput.wh-form input[type=checkbox]:hover + label:before,
.wh-styledinput.wh-form input[type=checkbox]:checked + label:before,
.wh-styledinput.wh-form input[type=radio]:hover + label:before,
.wh-styledinput.wh-form input[type=radio]:checked + label:before {
  border-color: #59280b;
}
.wh-styledinput.wh-form input[type=checkbox]:checked + label:after {
  content: "\f00c";
  display: inline-block;
  color: #59280b;
  font-family: "FontAwesome";
  font-size: 16px;
  position: absolute;
  left: 2px;
  top: 5px;
}
.wh-styledinput.wh-form input[type=radio]:checked + label:after {
  content: "";
  display: inline-block;
  background-color: #59280b;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  position: absolute;
  left: 4px;
  top: 8px;
}
.wh-styledinput.wh-form input[type=radio][disabled] + label,
.wh-styledinput.wh-form input[type=checkbox][disabled] + label {
  opacity: 0.5;
  cursor: default;
}
.wh-styledinput.wh-form .wh-form__optiondata {
  padding-left: 15px;
}
.wh-styledinput.wh-form .wh-form__optionlabel, .wh-styledinput.wh-form .wh-form__subfield > label {
  padding-right: 15px;
}
.wh-styledinput.wh-form .wh-form__optiondata--horizontal {
  margin-right: 30px;
}
.wh-styledinput.wh-form .wh-form__optiondata--horizontal:last-child {
  margin-left: 0;
}
.wh-styledinput.wh-form input[type=file] {
  outline: none;
  line-height: 22px;
  font: inherit;
}
.wh-styledinput.wh-form input[type=text],
.wh-styledinput.wh-form input[type=email],
.wh-styledinput.wh-form input[type=password],
.wh-styledinput.wh-form input[type=date],
.wh-styledinput.wh-form input[type=number],
.wh-styledinput.wh-form input[type=url],
.wh-styledinput.wh-form select,
.wh-styledinput.wh-form textarea {
  border: 1px solid #59280b;
  border-radius: 0;
  box-shadow: 0 none;
  height: 33px;
  line-height: 20px;
  font: inherit;
  padding: 5px 10px;
  background-color: #fff;
  outline: none;
  flex: 1;
}
.wh-styledinput.wh-form input[type=text]:hover, .wh-styledinput.wh-form input[type=text]:focus,
.wh-styledinput.wh-form input[type=email]:hover,
.wh-styledinput.wh-form input[type=email]:focus,
.wh-styledinput.wh-form input[type=password]:hover,
.wh-styledinput.wh-form input[type=password]:focus,
.wh-styledinput.wh-form input[type=date]:hover,
.wh-styledinput.wh-form input[type=date]:focus,
.wh-styledinput.wh-form input[type=number]:hover,
.wh-styledinput.wh-form input[type=number]:focus,
.wh-styledinput.wh-form input[type=url]:hover,
.wh-styledinput.wh-form input[type=url]:focus,
.wh-styledinput.wh-form select:hover,
.wh-styledinput.wh-form select:focus,
.wh-styledinput.wh-form textarea:hover,
.wh-styledinput.wh-form textarea:focus {
  border-color: #59280b;
}
.wh-styledinput.wh-form input[type=date] {
  max-width: 160px;
}
.wh-styledinput.wh-form textarea {
  height: 120px;
  padding: 10px;
}
.wh-styledinput.wh-form .wh-form__buttongroup {
  text-align: right;
}
.wh-styledinput.wh-form .wh-form__buttongroup:after {
  content: "";
  display: block;
  clear: both;
}
.wh-styledinput.wh-form .wh-form__buttongroup .wh-form__button + .wh-form__button {
  margin-left: 15px;
}
.wh-styledinput.wh-form .wh-form__buttongroup .wh-form__button--previous {
  float: left;
}
.wh-styledinput.wh-form .wh-form__button {
  display: inline-block;
  text-align: left;
  text-decoration: none;
  background-color: #6cbc21;
  border-radius: 0;
  border: 0 none;
  color: #fff;
  height: 38px;
  padding: 9px 20px;
  box-shadow: none;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  position: relative;
  transition: opacity 0.2s;
  font: normal 18px/20px "AutoPro-Regular", Arial;
}
.wh-styledinput.wh-form .wh-form__button:hover {
  opacity: 0.8;
}
.wh-styledinput.wh-form .wh-form__error {
  display: none;
  padding: 5px 0 0 25px;
  position: relative;
  color: red;
  font-size: 14px;
  line-height: 20px;
}
.wh-styledinput.wh-form .wh-form__error:after {
  content: "\f071";
  display: inline-block;
  font-family: "FontAwesome";
  font-size: 14px;
  position: absolute;
  left: 4px;
  top: 5px;
}
.wh-styledinput.wh-form .wh-form__fieldgroup--error .wh-form__error {
  display: block;
}
.wh-styledinput.wh-form .wh-form__errors {
  list-style-type: none;
  margin: 0;
  display: block;
  padding: 0;
}
.wh-styledinput.wh-form .wh-form__fieldgroup--error input, .wh-styledinput.wh-form .wh-form__fieldgroup--error select, .wh-styledinput.wh-form .wh-form__fieldgroup--error textarea, .wh-styledinput.wh-form .wh-form__fieldgroup--error input[type=radio] + label:before, .wh-styledinput.wh-form .wh-form__fieldgroup--error input[type=checkbox] + label:before {
  background-color: rgba(255, 0, 0, 0.1) !important;
}
.wh-styledinput.wh-form .wh-form__fieldgroup--error .wh-form__errors {
  display: block;
}
@media (max-width: 1000px) {
  .wh-styledinput.wh-form .wh-form__fieldgroup > .wh-form__label {
    width: 200px;
    padding-right: 15px;
  }
  .wh-styledinput.wh-form .wh-form__fields {
    max-width: calc(100% - 200px);
  }
}
@media (max-width: 800px) {
  .wh-styledinput.wh-form .wh-form__subfield {
    padding-top: 10px;
    width: 100%;
    display: block;
  }
  .wh-styledinput.wh-form .wh-form__subfieldlabel {
    padding-right: 0;
    padding-bottom: 10px;
    width: 100%;
  }
}
@media (max-width: 600px) {
  .wh-styledinput.wh-form .wh-form__fieldgroup {
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
  }
  .wh-styledinput.wh-form .wh-form__fieldgroup > .wh-form__label {
    text-align: left;
    padding-right: 0;
    width: 100%;
  }
  .wh-styledinput.wh-form .wh-form__fields {
    margin-top: 10px;
    width: 100%;
    max-width: 100%;
  }
}
@media (max-width: 600px) {
  .wh-styledinput.wh-form .wh-form__fieldgroup > .wh-form__label {
    padding-right: 15px;
  }
}

@media print {
  .wh-form__fieldgroup {
    page-break-inside: avoid;
    padding-bottom: 10px;
  }
  .wh-styledinput.wh-form .wh-form__fieldgroup {
    padding-bottom: 10px;
  }
  .wh-styledinput.wh-form .wh-form__page * + .wh-form__fieldgroup--richtext {
    margin-top: 0;
  }
  .wh-styledinput.wh-form .wh-form__fields {
    margin-top: 5px !important;
  }
}
/* css styled pulldown */
.wh-form__pulldown-styled {
  position: relative;
  flex: 1;
}
.wh-form__pulldown-styled > select {
  padding-right: 30px !important;
  width: 100%;
  min-width: 140px;
  text-overflow: ellipsis;
}
.wh-form__pulldown-styled > select:disabled {
  opacity: 0.4;
}
.wh-form__pulldown-styled > select:disabled + .arrow {
  opacity: 0.4;
}
.wh-form__pulldown-styled select::-ms-expand {
  display: none; /* hide select arrow in IE */
}
.wh-form__pulldown-styled .arrow {
  font-size: 20px;
  position: absolute;
  right: 1px;
  top: 1px;
  bottom: 1px;
  width: 30px;
  color: #59280b;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wh-form__pulldown-styled select[disabled] + .arrow {
  opacity: 0.2;
}