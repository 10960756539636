.rpc__busyoverlay {
  display: none;
  z-index: 10000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
html.dompack--busymodal .rpc__busyoverlay {
  display: block;
}
.rpc__busyoverlay .spinner {
  display: block;
  position: absolute;
  color: #fff;
  font-size: 80px;
  top: 50%;
  left: 50%;
  margin-top: -40px;
  margin-left: -40px;
  opacity: 0.8;
}

.medlon-dialog__modalbg {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  white-space: nowrap;
  vertical-align: middle;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.medlon-dialog__modalbg:before {
  display: inline-block;
  content: "";
  vertical-align: middle;
  line-height: 100%;
  height: 100%;
  width: 0;
}
.medlon-dialog__holder {
  text-align: left;
  display: inline-block;
  white-space: normal;
  font-family: inherit;
  color: inherit;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}
.medlon-dialog__holder {
  background: #ffffff;
  padding: 20px;
  max-width: 930px;
  border-radius: 0;
  min-height: 50px;
  max-height: calc(100vh - 40px);
  max-width: calc(100vw - 40px);
  box-shadow: 0 15px 30px 0 rgba(24, 31, 75, 0.2);
}
.medlon-dialog--thuisprikroutes {
  width: 400px;
  max-width: 100%;
  padding: 10px;
}
.medlon-dialog__modalbg {
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1001;
}
.medlon-dialog__content {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: calc(100vh - 170px);
}
.medlon-dialog__content h2 {
  margin-bottom: 10px;
}
.medlon-dialog__content > *:last-child {
  margin-bottom: 0;
}
.medlon-dialog__holder {
  overflow: visible;
}
.medlon-dialog__close {
  border-radius: 50%;
  cursor: pointer;
  width: 20px;
  height: 20px;
  font-size: 12px;
  color: #fff;
  background-color: #59280b;
  position: absolute;
  right: 0;
  top: 0;
  margin: -10px -10px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
}