
  .embeddedobject.itemslist
, .wh-rtd-editor.itemlist
{
  width: 100%;
  margin: 20px 0;
}
  .embeddedobject.itemslist::after
, .wh-rtd-editor.itemlist::after
{
  display: block;
  content: '';
  clear: both;
}
  .embeddedobject.itemslist > *
, .wh-rtd-editor.itemslist > *
{
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  float: left;
}
  .embeddedobject.itemslist img
, .wh-rtd-editor.itemslist img
{
  max-width: 100%;
  height: auto;
}
  .embeddedobject.itemslist > *:nth-child(2n+1)
, .wh-rtd-editor.itemslist > *:nth-child(2n+1)
{
  clear: both;
  width: 20%;
  padding-right: 10px;
}
.wh-rtd-editor.itemslist > *:nth-child(2n+1)
{
  border-right: 1px solid #ddd;
}
  .embeddedobject.itemslist > *:nth-child(2n+2)
, .wh-rtd-editor.itemslist > *:nth-child(2n+2)
{
  width: 80%;
  padding-left: 10px;
}
  .embeddedobject.itemslist.twentyeighty > *:nth-child(2n+1)
, .wh-rtd-editor.itemslist.twentyeighty > *:nth-child(2n+1)
{
  width: 20%;
}
  .embeddedobject.itemslist.twentyeighty > *:nth-child(2n+2)
, .wh-rtd-editor.itemslist.twentyeighty > *:nth-child(2n+2)
{
  width: 80%;
}
  .embeddedobject.itemslist.fiftyfifty > *
, .wh-rtd-editor.itemslist.fiftyfifty > *
{
  width: 50%;
}
@media (max-width:750px)
{
  .embeddedobject.itemslist > *
  {
    float: none !important;
    width: 100% !important;
    padding: 0 !important;
  }
  .embeddedobject.itemslist > *:nth-child(2n+2)
  {
    margin-top: -20px;
  }
}
