#thuisprikroutes {
  display: none;
}
#thuisprikroutes.active {
  background-color: #eef4f1;
  padding: 20px 30px 20px;
  border-bottom: 1px solid #6cbc21;
  line-height: 150%;
  margin-bottom: 15px;
  display: block;
}
@media (max-width: 750px) {
  #thuisprikroutes.active {
    border-top: 1px solid #6cbc21;
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
    padding-right: 20px;
  }
}
#thuisprikroutes .title {
  font-weight: bold;
  font-size: 18px;
  padding-bottom: 5px;
}
#thuisprikroutes .link {
  display: inline-block;
  margin-top: 15px;
  background-color: #6CBC22;
  font: normal 19px/20px "AutoPro-Regular", Arial;
  color: #fff;
  text-decoration: none;
  height: 38px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 9px 20px;
  line-height: 20px;
  opacity: 1;
  transition: opacity 0.2s;
}
#thuisprikroutes .link:hover {
  opacity: 0.8;
}
#thuisprikroutes .exception {
  padding-top: 10px;
}
#thuisprikroutes .exception ul {
  margin-bottom: 0;
}

#imageheader {
  background-color: #f6f9f8;
  height: 490px;
  position: relative;
}

#imageheader > .image {
  max-width: 1480px;
  margin: 0 auto;
  position: relative;
  height: 100%;
  overflow: hidden;
}

#imageheader > .image > img {
  position: absolute;
  top: 0;
  left: 0;
}

#imageheader > .innerwrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

#imageheader > .innerwrapper > .inner {
  max-width: 1020px;
  margin: 0 auto;
  position: relative;
  height: 100%;
}

#headerslides {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#headerslides > img {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#imageheader .sectiontitle {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  display: none;
  height: 50px;
  background-color: rgba(255, 255, 255, 0.7);
}

#imageheader .sectiontitle > span {
  display: block;
  color: #59280b;
  text-transform: uppercase;
  font: normal 30px/30px "AutoPro-Regular", Arial;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 11px 20px 9px;
  letter-spacing: 1px;
}

#imageheader .introduction {
  position: absolute;
  bottom: 35px;
  left: 20px;
  width: 360px;
  color: #fff;
}

#imageheader .introduction h1 {
  padding: 15px;
  font: normal 20px/20px "AutoPro-Bold", Arial;
  opacity: 0.9;
}

#imageheader .introduction p {
  padding: 15px;
  opacity: 0.9;
  line-height: 20px;
}

#imageheader .quicklinks {
  position: absolute;
  left: 20px;
  top: 240px;
  width: 310px;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

#imageheader .quicklinks.right {
  left: auto;
  top: auto;
  right: 20px;
  bottom: 35px;
}

#imageheader .quicklinks li + li {
  margin-top: 2px;
}

#imageheader .quicklinks li a {
  display: block;
  text-decoration: none;
  position: relative;
  padding: 10px 50px 10px 15px;
  color: #fff;
  height: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font: normal 24px/30px "AutoPro-Bold", Arial;
}

#imageheader .quicklinks li a > .arrow {
  position: absolute;
  display: block;
  width: 50px;
  height: 50px;
  right: 0;
  top: 0;
  padding: 10px 18px;
  line-height: 30px;
  transition: background-color 0.3s;
}

/* map in imageheader */
#imageheader > .map {
  max-width: 1480px;
  margin: 0 auto;
  position: relative;
  height: 100%;
  overflow: hidden;
}

#imageheader #gmap {
  background: url(../web/img/placeholder-map.png) 0 0 no-repeat #fff;
}

html[data-wh-consent*=thirdparty] #imageheader #gmap {
  background-image: none;
}

#imageheader > .map > #gmap,
#imageheader > .map > .mapoverlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 8px;
  left: 0;
}

#imageheader > .map > .mapoverlay {
  display: none;
}

#imageheader > .map > .inner {
  max-width: 1020px;
  margin: 0 auto;
  position: relative;
  height: 0;
}

#kaartnav {
  position: absolute;
  top: 0;
  padding: 20px 20px 20px 25px;
  height: 490px;
  right: 0;
  width: 250px;
}

#kaartnav .title {
  color: #fff;
  font: normal 30px/26px "AutoPro-Bold", Arial;
}

#kaartnav .title > small {
  font-size: 22px;
  display: block;
}

#kaartnav > .maskright {
  position: absolute;
  left: 230px;
  top: 0;
  bottom: 0;
  width: 250px;
}

#maplegenda {
  position: relative;
  padding: 5px 0 20px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  line-height: 21px;
}

#maplegenda > span {
  display: inline-block;
  padding-left: 15px;
}

#maplegenda img {
  width: 16px;
  height: 21px;
  vertical-align: top;
}

#imageheader .bottomline {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 8px;
  opacity: 0.85;
}

#locationsearchform {
  margin-top: 80px;
  width: 170px;
}

#locationsearchform > * {
  width: 100%;
  height: 38px;
  display: block;
  border: 0 none;
  border-radius: 0;
  -webkit-appearance: none;
  margin-bottom: 15px;
  padding: 5px 10px;
  line-height: 28px;
  color: #59280b;
  text-align: left;
  font-size: 14px;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  #locationsearchform > * {
    font-size: 16px; /* prevent auto zoom on ios */
  }
}
#locationsearchform > button {
  color: #fff;
  line-height: 25px;
  position: relative;
  cursor: pointer;
}

#locationsearchform > button > span {
  display: inline-block;
  position: absolute;
  right: 10px;
  top: 2px;
  font-size: 32px;
}

#printlocations {
  display: inline-block;
  position: absolute;
  left: 25px;
  bottom: 20px;
  opacity: 0;
  transition: opacity 0.3s;
}

#printlocations.enabled {
  cursor: pointer;
  opacity: 1;
}

#printlocations > span {
  font-size: 18px;
}

#mapresults .icon {
  width: 16px;
  height: 21px;
  display: block;
  position: absolute;
  top: 10px;
  left: 0;
}

#mapresults {
  margin-bottom: 15px;
}

#mapresults a {
  color: inherit;
}

#mapresults a.appointment {
  color: #e6007e;
}

.tabscontainer {
  list-style-type: none;
  margin: 5px 0;
  padding: 0;
  position: relative;
  white-space: nowrap;
  vertical-align: top;
  display: block;
}

.tabscontainer .tab-title {
  font-weight: bold;
  position: relative;
  cursor: pointer;
  white-space: nowrap;
}

.tabscontainer > li .tab-content .remark {
  display: block;
  padding-bottom: 14px;
}

#mapresults p + p {
  margin-top: 5px;
}

#mapresults > ul > li > a {
  display: inline-block;
  position: absolute;
  top: -85px;
  left: 0;
}

#mapresults > ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

#mapresults .day {
  display: inline-block;
  min-width: 75px;
}

#mapresults > ul > li {
  position: relative;
  padding: 10px 0px 15px 30px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  line-height: 20px;
}

#mapresults h3 {
  line-height: 21px;
}

#gmap .wh-infowindow {
  background-color: #fff;
  box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.3);
  width: 300px;
  margin-left: -150px;
}

#gmap .wh-infowindow .extranote {
  display: block;
  color: #fff;
  margin: 5px -15px -10px;
  padding: 10px 15px;
  font-family: Arial;
  font-size: 14px;
  line-height: 20px;
}

#gmap .wh-infowindow .title {
  padding: 10px 15px;
  color: #59280b;
  font: normal 16px/18px "AutoPro-Bold", Arial;
}

#gmap .wh-infowindow a {
  display: block;
  text-decoration: none;
}

#gmap .wh-infowindow a.route {
  height: 30px;
  color: #fff;
  position: relative;
  padding: 5px 35px 5px 15px;
  font: normal 16px/20px "AutoPro-Bold", Arial;
}

#gmap .wh-infowindow a.route > .arrow {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  height: 30px;
  width: 30px;
  text-align: center;
  padding: 7px 0 0 3px;
}

html:not(.printprikposten) .tabscontainer .tab-title {
  padding: 4px 10px;
  height: 32px;
}
html:not(.printprikposten) .prikposttype {
  display: none;
}
html:not(.printprikposten) .tabscontainer > li {
  display: inline-block;
  white-space: normal;
  vertical-align: top;
  width: 200px;
}
html:not(.printprikposten) .tabscontainer:before {
  content: "";
  display: block;
  position: absolute;
  border-bottom: 1px solid #ccc;
  top: 31px;
  left: 0;
  right: 0;
}
html:not(.printprikposten) .tabscontainer > li.active .tab-title {
  border-left: 1px solid #ccc;
  border-top: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-radius: 3px 3px 0 0;
  background-color: #fff;
  border-bottom: 1px solid #fff;
  cursor: default;
  color: #e6007e;
}
html:not(.printprikposten) .tabscontainer > li .tab-content {
  padding: 14px 0 5px;
  width: 200%;
  /*
  position: absolute;
  left: 0;
  display: none;
  */
  visibility: hidden;
}
html:not(.printprikposten) .tabscontainer > li.active .tab-content {
  display: block;
  visibility: visible;
}
html:not(.printprikposten) .tabscontainer > li + li.active .tab-content {
  margin-left: -200px;
}

html.printprikposten #imageheader, html.printprikposten header,
html.printprikposten #afterheader,
html.printprikposten #maplegenda {
  display: none;
}
html.printprikposten body {
  max-width: 650px;
  margin: 10px auto 30px;
  position: relative;
}
html.printprikposten main {
  display: block !important;
}
html.printprikposten main:before {
  content: "";
  display: block;
  width: 200px;
  height: 74px;
  background: url(../web/img/logo-unilabs-nl.svg) no-repeat;
  margin-left: auto;
}
html.printprikposten .prikposttype {
  display: block;
}
html.printprikposten img.logo {
  float: right;
}
html.printprikposten h1 {
  clear: both;
}
html.printprikposten h3 {
  margin-bottom: 5px;
}
html.printprikposten p {
  line-height: 20px;
}
html.printprikposten p + p {
  margin-top: 5px;
}
html.printprikposten ul {
  margin: 10px 0;
  padding: 0;
  list-style-type: none;
}
html.printprikposten ul > li {
  margin: 0 0 20px;
}
html.printprikposten #mapresults > ul > li {
  padding-left: 0;
}
html.printprikposten #mapresults .icon {
  display: none;
}
html.printprikposten .tabscontainer > li {
  display: block;
}
html.printprikposten table {
  margin: 0;
  padding: 0;
  border: 0 none;
  border-collapse: collapse;
}
html.printprikposten table th,
html.printprikposten table td {
  font-weight: normal;
  vertical-align: top;
  text-align: left;
}
html.printprikposten table td {
  padding-left: 10px;
}
html.printprikposten html:not(.printprikposten) .tabscontainer .tab-title {
  padding-left: 0;
  padding-bottom: 0;
}
html.printprikposten a.route {
  display: none;
}

@media (max-width: 920px) {
  #imageheader,
  #imageheader > .image,
  #imageheader > .map {
    height: 430px;
  }
  #imageheader .sectiontitle {
    display: block;
  }
}
@media (max-width: 750px) {
  #imageheader {
    height: auto;
    min-height: 215px;
  }
  #imageheader.imageheader--map {
    min-height: 165px;
  }
  #imageheader.imageheader--map > .map {
    height: 165px;
  }
  #imageheader > .map > .mapoverlay {
    display: block;
  }
  #imageheader .sectiontitle {
    top: auto;
    bottom: 0;
    right: 50px;
  }
  #imageheader .sectiontitle > span {
    font-size: 20px;
  }
  #kaartnav > .maskright,
  #printlocations,
  #maplegenda,
  #mapresults > ul > li > .icon {
    display: none;
  }
  #kaartnav {
    width: 100%;
    right: 0;
    left: 0;
    top: 0;
    height: 200px;
    bottom: 0;
  }
  #kaartnav .title {
    font-size: 23px;
  }
  #kaartnav .title > small {
    display: inline;
    font-size: 100%;
  }
  #locationsearchform {
    margin-top: 20px;
    width: 100%;
  }
  #locationsearchform > input {
    margin-bottom: 10px;
  }
  #locationsearchform > .wh-pulldown {
    width: calc(100% - 120px);
    float: left;
  }
  #locationsearchform > button {
    width: 115px;
    float: right;
  }
  #mapresults {
    margin-top: -10px;
  }
  #mapresults > ul > li {
    padding-left: 0;
  }
  #imageheader > .image,
  #imageheader > .map {
    height: 215px;
  }
  #imageheader .bottomline {
    display: none;
  }
  #imageheader > .innerwrapper {
    position: relative;
  }
  #imageheader > .innerwrapper > .inner {
    height: auto;
  }
  #imageheader .introduction,
  #imageheader .quicklinks,
  #imageheader .quicklinks.right {
    position: relative;
    left: auto;
    right: auto;
    bottom: auto;
    top: auto;
    width: 100%;
  }
  #imageheader .quicklinks {
    border-top: 2px solid #fff;
    border-bottom: 2px solid #fff;
  }
  #imageheader .introduction h1 {
    color: #59280b;
    padding-bottom: 0;
    background-color: #fff !important;
  }
  #imageheader .introduction p {
    padding-top: 5px;
    color: #59280b;
    background-color: #fff !important;
  }
}
@media (max-width: 450px) {
  html:not(.printprikposten) .tabscontainer {
    margin: 5px 0;
    padding: 0;
  }
  html:not(.printprikposten) .tabscontainer:before {
    display: none;
  }
  html:not(.printprikposten) .tabscontainer > li {
    display: block;
    width: 100%;
  }
  html:not(.printprikposten) .tabscontainer > li + li {
    border-top: 1px solid #f0f0f0;
  }
  html:not(.printprikposten) .tabscontainer .tab-title {
    padding: 4px 0;
    height: auto;
  }
  html:not(.printprikposten) .tabscontainer > li .tab-title {
    border: 0 none !important;
    cursor: default;
  }
  html:not(.printprikposten) .tabscontainer > li .tab-content {
    padding: 0 0 5px;
    width: 100%;
    margin-left: 0 !important;
  }
  html:not(.printprikposten) .tabscontainer > li .tab-content {
    position: relative;
    display: block;
    visibility: visible;
  }
  html:not(.printprikposten) .tabscontainer > li .tab-content .remark {
    padding-bottom: 5px;
  }
}