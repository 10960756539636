.wh-autocomplete-values {
  list-style-type: none;
  display: block;
  margin: 0;
  padding: 10px 0;
  position: absolute;
  top: 42px;
  left: 0;
  right: 120px;
  background-color: #fff;
  border: 1px solid #59280B;
  box-shadow: 4px 4px 7px 0 rgba(0, 0, 0, 0.1) !important;
  z-index: 10;
  width: auto;
}
.wh-autocomplete-values > li {
  padding: 3px 15px;
  font-weight: 500;
  line-height: 28px;
  cursor: pointer;
  background-color: #fff;
  transition: background-color 0.15s;
  outline: none;
  color: #3e3e36;
  margin-bottom: 0 !important;
}
.wh-autocomplete-values > li:before {
  display: none !important;
}
.wh-autocomplete-values > li.noresults {
  font-weight: normal;
  font-style: italic;
  color: #999;
  cursor: default;
}
.wh-autocomplete-values > li.suggestion:focus, .wh-autocomplete-values > li.suggestion:hover {
  background-color: #EEF4F1;
}
.wh-autocomplete-values > li .match {
  font-weight: bold;
}