.metanav--weblon > * {
  display: inline-block;
  height: 20px;
  font: normal 16px/18px "AutoPro-Bold", Arial;
  text-decoration: none;
}
.metanav--weblon > * + * {
  margin-left: 15px;
}

.wh-wrdauth-form .wh-form.wh-styledinput {
  max-width: 500px;
}
.wh-wrdauth-form .wh-form.wh-styledinput p {
  margin: 15px 0;
}
.wh-wrdauth-form .wh-form.wh-styledinput h1 {
  margin-bottom: 20px;
}
.wh-wrdauth-form .wh-form.wh-styledinput .wh-form__fieldgroup {
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.wh-wrdauth-form .wh-form.wh-styledinput .wh-form__fieldgroup > .wh-form__label {
  text-align: left;
  padding-right: 0;
  width: 100%;
}
.wh-wrdauth-form .wh-form.wh-styledinput .wh-form__fields {
  margin-top: 10px;
  width: 100%;
  max-width: 100%;
}