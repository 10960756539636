
.embeddedobject.twocolumns::after
{
  content: '';
  display: block;
  clear: both;
}
.embeddedobject.twocolumns > .leftcol
{
  float: left;
  width: 50%;
  padding-right: 10px;
}
.embeddedobject.twocolumns > .rightcol
{
  float: right;
  width: 50%;
  padding-left: 10px;
}
@media (max-width:640px)
{
    .embeddedobject.twocolumns > .leftcol
  , .embeddedobject.twocolumns > .rightcol
  {
    float: none;
    padding: 0;
    width: 100%;
  }
}
