#phonelistintro {
  background-color: #b7007a;
  color: #fff;
  height: 131px;
  position: relative;
  padding: 20px;
  border-top: 0;
}
#phonelistintro .arrow {
  display: block;
  width: 14px;
  height: 27px;
  background: transparent url(../../web/img/arrows.png) 0 -30px no-repeat;
  position: absolute;
  left: 0;
  top: 0;
}

#phonelistdescr {
  margin: 10px 0px;
  border-top: 2px solid #b7007a;
  border-bottom: 1px solid #b7007a;
  padding: 5px 20px;
}

#phonelist {
  margin: 15px 0px;
  padding: 0px;
  list-style-type: none;
}
#phonelist #phonelistresults {
  display: none;
}
#phonelist h3 {
  background-color: #b7007a;
  position: relative;
  height: 50px;
  color: white;
  display: block;
  line-height: 50px;
  text-indent: 20px;
  cursor: pointer;
}
#phonelist h3 .arrow {
  display: block;
  width: 14px;
  height: 27px;
  background: transparent url(../../web/img/arrows.png) 0px -30px no-repeat;
  position: absolute;
  left: 0px;
  top: 0px;
}
#phonelist h3 .arrowright {
  display: block;
  width: 11px;
  height: 21px;
  background: transparent url(../../web/img/arrows.png) 0px -90px no-repeat;
  position: absolute;
  right: 10px;
  top: 15px;
  white-space: nowrap;
}
#phonelist h3 .arrowright span {
  display: block;
  position: absolute;
  text-align: right;
  right: 20px;
  top: 0px;
  color: white;
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  font-weight: normal;
}
#phonelist li.active h3 .arrowright {
  display: none;
}
#phonelist table {
  display: none;
  padding: 0 0;
  margin: 2px 0px 0px;
  width: 100%;
  border-collapse: collapse;
  background-color: #f6f9f8;
}
#phonelist li {
  margin-bottom: 3px;
}
#phonelist li.active table,
#phonelist li#phonelistresults table {
  display: table;
}
#phonelist td {
  padding: 4px 10px 4px 15px;
  font-size: 12px;
  text-align: left;
  vertical-align: top;
}
#phonelist td span.right {
  float: right;
}
#phonelist td + td, #phonelist th + th {
  padding-left: 5px;
}
#phonelist tr.first td {
  padding-top: 15px;
}
#phonelist tr.odd td {
  background-color: #e9efed;
}
#phonelist th {
  font-size: 13px;
  text-align: left;
  vertical-align: top;
  padding: 0px 10px 0px 15px;
  background-color: #ccbac6;
  color: #b7007a;
  height: 23px;
  line-height: 23px;
  white-space: nowrap;
  overflow: hidden;
}

#phonelistdescr span.results {
  display: none;
}

html.showphoneresults #phonelist li,
html.showphoneresults #phonelistdescr span {
  display: none;
}
html.showphoneresults #phonelist li#phonelistresults,
html.showphoneresults #phonelistdescr span.results {
  display: block;
}

/* search/overview */
#phone_searchform {
  display: block;
  margin-top: 20px;
  max-width: 570px;
}

#phone_searchform #phone_terms {
  height: 20px;
  width: 100%;
  display: block;
  text-indent: 5px;
  border: 0px none;
  color: #6f320e;
  font-size: 11px;
  line-height: 20px;
}

#phone_submitholder {
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 24px;
  background-color: white;
  width: 610px;
  max-width: 100%;
}

#phone_submitholder #phone_submit {
  height: 20px;
  margin-top: 4px;
  color: white;
  border: 0px none;
  width: 590px;
  max-width: 100%;
  text-align: right;
  line-height: 20px;
  font-size: 11px;
  font-weight: bold;
  padding-right: 25px;
  cursor: pointer;
  background: transparent url(../../web/img/bg_btn.png) no-repeat 100% -120px;
}

#phone_submitholder #phone_submit:hover {
  background-position: 100% -330px;
}