#vademecum-largeimages {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000; /* on top of page header */
  background-color: rgba(0, 0, 0, 0.75);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px 0;
}
#vademecum-largeimages .close {
  color: #fff;
  font-size: 36px;
  display: inline-block;
  width: 36px;
  height: 36px;
  position: absolute;
  top: 0px;
  right: 30px;
  cursor: pointer;
  opacity: 0.6;
  transition: opacity 0.2s;
}
#vademecum-largeimages .close:hover {
  opacity: 0.8;
}
#vademecum-largeimages .close .fa {
  margin-left: -4px;
}
#vademecum-largeimages .close .fa + .fa {
  margin-left: 4px;
}
#vademecum-largeimages .holder {
  display: inline-block;
  max-width: 100%;
  max-height: 100%;
}
#vademecum-largeimages .holder > * {
  flex: none;
}
#vademecum-largeimages .holder .largeimage {
  transition: opacity 0.4s;
  background-color: #fff;
  position: relative;
  display: inline-block;
  width: 100vw;
  max-height: 100vh;
}
#vademecum-largeimages .holder .largeimage > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.4s;
}
#vademecum-largeimages .holder .jumpbuttons {
  text-align: center;
  padding: 10px 0;
}
#vademecum-largeimages .holder .jumpbuttons span {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  border: 1px solid #6CBC22;
  cursor: pointer;
  margin: 0 2px;
}
#vademecum-largeimages .holder .jumpbuttons span:hover, #vademecum-largeimages .holder .jumpbuttons span.active {
  background-color: #6CBC22;
}

#vademecum-header {
  box-shadow: 0 4px 2px 0 rgba(0, 0, 0, 0.12);
  min-height: 120px;
  position: relative;
  z-index: 1;
}
#vademecum-header > .inner {
  margin-bottom: 20px;
  padding-right: 275px;
}
@media (max-width: 920px) {
  #vademecum-header > .inner {
    padding-right: 20px;
  }
}

html.vademecum #sharepage {
  margin-top: 30px;
}

html.vademecum #rightcol {
  position: absolute;
  right: 20px;
  top: 0;
}

#vademecum-index .inner {
  padding-right: 275px;
}
@media (max-width: 920px) {
  #vademecum-index .inner {
    padding-right: 20px;
  }
}
#vademecum-index .wh-autocomplete-values {
  top: 37px;
}
#vademecum-index .tabs > * {
  display: inline-block;
  margin-right: 5px;
  border: 1px solid #81AA93;
  background-color: #EEF4F1;
  color: #000;
  min-width: 135px;
  padding: 0 10px;
  text-align: center;
  font: normal 16px/30px "AutoPro-Bold", Arial;
  text-decoration: none;
}
#vademecum-index .tabs > *:hover, #vademecum-index .tabs > *.active {
  background-color: #81AA93;
}
#vademecum-index .feedback {
  padding-top: 12px;
  font-size: 13px;
}
#vademecum-index form {
  height: 75px;
  position: relative;
  padding: 0 0 15px;
  margin-top: 10px;
}
#vademecum-index form > * {
  -webkit-appearance: none;
  outline: none;
  text-align: left;
  font-size: 17px;
  box-shadow: none;
}
#vademecum-index form input {
  height: 38px;
  width: 100%;
  border: 1px solid #81AA93;
  background-color: #fff;
  padding: 0 135px 0 15px;
}
#vademecum-index form button {
  position: absolute;
  right: 0;
  top: 0;
  background-color: #59280b;
  border: 0 none;
  color: #fff;
  cursor: pointer;
  height: 38px;
  width: 120px;
  padding-left: 10px;
}
#vademecum-index form button > .fa {
  position: absolute;
  top: 9px;
  right: 10px;
}
#vademecum-index .az_list {
  list-style-type: none;
  margin: 0 0 30px -5px;
  padding: 10px 0 15px;
}
#vademecum-index .az_list li {
  padding-left: 5px;
  padding-bottom: 5px;
  color: #fff;
  display: inline-block;
  width: 30px;
  height: 30px;
}
#vademecum-index .az_list li .letter {
  width: 100%;
  height: 100%;
  text-align: center;
  font: normal 16px/26px Arial;
  background-color: #6CBC22;
  cursor: pointer;
  transition: background-color 0.2s;
}
#vademecum-index .az_list li .letter:hover {
  background-color: #3F7909;
}
#vademecum-index .az_list li.disabled .letter {
  background-color: #C0BFC1;
  cursor: default;
}

#vademecum-search {
  padding: 30px 0;
}
#vademecum-search.inner {
  padding-right: 275px;
}
@media (max-width: 920px) {
  #vademecum-search.inner {
    padding-right: 20px;
  }
}
#vademecum-search .searchresults {
  list-style-type: none;
  margin: 15px 0;
  padding: 0;
  border-top: 1px solid #6CBC22;
}
#vademecum-search .searchresults > li {
  border-bottom: 1px solid #6CBC22;
}
#vademecum-search .searchresults a {
  text-decoration: none;
  display: block;
  padding: 10px 0;
  color: inherit;
}
#vademecum-search .searchresults a > .title {
  display: block;
  font-size: 17px;
  padding-bottom: 5px;
}
#vademecum-search .searchresults a > .path {
  display: block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#vademecum-summary.inner {
  padding-right: 275px;
}
@media (max-width: 920px) {
  #vademecum-summary.inner {
    padding-right: 20px;
  }
}
#vademecum-summary ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
#vademecum-summary > ul > li {
  display: block;
  font: normal 24px/40px "AutoPro-Bold", Arial;
  padding-bottom: 1px;
}
#vademecum-summary > ul > li > .letter {
  color: #fff;
  background-color: #6CBC22;
  transition: background-color 0.2s;
  text-align: center;
  cursor: pointer;
}
#vademecum-summary > ul > li:hover > .letter, #vademecum-summary > ul > li.active > .letter {
  background-color: #3F7909;
}
#vademecum-summary > ul > li > ul {
  display: none;
}
#vademecum-summary > ul > li.active > ul {
  display: block;
}
#vademecum-summary > ul > li > ul li {
  font-size: 18px;
  line-height: 20px;
  min-height: 40px;
}
#vademecum-summary > ul > li > ul li + li {
  border-top: 1px solid #6CBC22;
}
#vademecum-summary > ul > li a {
  padding: 10px 30px 10px 10px;
  text-decoration: none;
  font: inherit;
  color: #59280B;
  display: block;
  position: relative;
  transition: background-color 0.2;
}
#vademecum-summary > ul > li a:hover {
  background-color: #EEF4F1;
}
#vademecum-summary > ul > li a:hover .fa {
  color: #6CBC22;
}
#vademecum-summary > ul > li a .fa {
  position: absolute;
  font-size: 24px;
  right: 15px;
  top: 50%;
  margin-top: -14px;
}

#vademecum-details.inner {
  padding-right: 275px;
}
@media (max-width: 920px) {
  #vademecum-details.inner {
    padding-right: 20px;
  }
}
#vademecum-details .block__title {
  background-color: #6CBC22;
  color: #fff;
  font: normal 24px/30px "AutoPro-Bold", Arial;
  padding: 5px 10px;
}
#vademecum-details .block__content {
  max-width: 100%;
  margin: 20px 0;
  position: relative;
}
#vademecum-details .block__content .scrollwrapper {
  overflow-x: auto;
}
#vademecum-details .block__content .scrollwrapper__content {
  display: inline-block;
  min-width: 100%;
}
#vademecum-details .block__content .scrollwrapper__content:after {
  clear: both;
  content: "";
  display: block;
}
#vademecum-details .block__content.scrollable.scrollable--right:after {
  content: "\f105";
  font-size: 16px;
  font-family: "FontAwesome";
  display: flex;
  position: absolute;
  align-items: center;
  padding-left: 3px;
  left: 100%;
  top: 0;
  bottom: 0;
  color: #6F320E;
  border-left: 1px solid #6F320E;
}
#vademecum-details .block__content:after {
  clear: both;
  display: block;
  content: "";
}
#vademecum-details .block__content table {
  margin: 0;
  border-collapse: collapse;
  display: table;
  border: 0 none;
  width: 100%;
}
#vademecum-details .block__content--2col table {
  width: 50%;
  float: left;
}
#vademecum-details .block__content .images {
  float: left;
  width: 50%;
}
#vademecum-details .block__content .images .images__slides {
  position: relative;
  border: 1px solid #D5D5D5;
  width: 200px;
  height: 200px;
  overflow: hidden;
  cursor: pointer;
  z-index: 0; /* below page header */
}
#vademecum-details .block__content .images .images__slides .image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  display: flex;
  transition: opacity 0.8s;
  align-items: center;
  justify-content: center;
}
#vademecum-details .block__content .images .images__slides .image.active {
  z-index: 1;
  opacity: 1;
}
#vademecum-details .block__content .images .nav {
  position: relative;
  width: 200px;
  margin-top: 10px;
  height: 10px;
}
#vademecum-details .block__content .images .nav .jumpbuttons {
  position: relative;
  text-align: center;
  padding: 1px 20px;
}
#vademecum-details .block__content .images .nav .jumpbuttons span {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  border: 1px solid #6CBC22;
  cursor: pointer;
  margin: 0 2px;
}
#vademecum-details .block__content .images .nav .jumpbuttons span:hover, #vademecum-details .block__content .images .nav .jumpbuttons span.active {
  background-color: #6CBC22;
}
#vademecum-details .block__content .images .nav .enlarge {
  position: absolute;
  border: 2px solid #6CBC22;
  border-radius: 3px;
  cursor: pointer;
  position: absolute;
  width: 17px;
  height: 18px;
  right: 0;
  top: 0;
  font-size: 12px;
  padding: 2px;
  transition: all 0.2s;
}
#vademecum-details .block__content .images .nav .enlarge:hover {
  background-color: #6CBC22;
  color: #fff;
}
#vademecum-details .block__content th, #vademecum-details .block__content td {
  vertical-align: top;
  text-align: left;
  font-size: 13px;
  padding: 5px 10px;
}
#vademecum-details .block__content td a {
  color: inherit;
}
#vademecum-details .block__content th {
  width: 25%;
  white-space: nowrap;
}
#vademecum-details .block__content td {
  width: 75%;
}
#vademecum-details .block__content--2col th, #vademecum-details .block__content--2col td {
  width: 50%;
}
@media (max-width: 600px) {
  #vademecum-details .block__content--2col .images {
    width: 200px;
    float: none;
    margin: 10px auto;
  }
  #vademecum-details .block__content--2col table {
    width: 100%;
    float: none;
  }
  #vademecum-details .block__content--2col table th {
    width: 25%;
  }
  #vademecum-details .block__content--2col table td {
    width: 75%;
  }
}
#vademecum-details .block__content .headertop th {
  border-bottom: 1px solid #6CBC22;
  width: 25%;
  padding-bottom: 10px;
}
#vademecum-details .block__content .headertop td {
  width: 25%;
}
#vademecum-details .block__content .headertop tr:nth-child(2) > * {
  padding-top: 10px;
}
#vademecum-details .block__content .headertop .maxwidth {
  width: 100%;
}
#vademecum-details .block__content .separator {
  clear: both;
  padding: 10px 0;
}
#vademecum-details .block__content .separator:after {
  content: "";
  display: block;
  border-top: 1px solid #6CBC22;
}
#vademecum-details .footer {
  font-size: 13px;
  border-top: 3px solid #6CBC22;
  padding: 15px 0;
  position: relative;
}
#vademecum-details .footer .disclaimer {
  display: inline-block;
  position: absolute;
  right: 0;
  top: 15px;
  color: inherit;
}