@font-face
{
  font-family: 'AutoPro-Regular';
  src: url('AutoPro-Regular.eot');
  src: url('AutoPro-Regular.eot?#iefix') format('embedded-opentype'),
       url('AutoPro-Regular.woff') format('woff'),
       url('AutoPro-Regular.ttf') format('truetype'),
       url('AutoPro-Regular.svg#AutoPro-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face
{
  font-family: 'AutoPro-Bold';
  src: url('AutoPro-Bold.eot');
  src: url('AutoPro-Bold.eot?#iefix') format('embedded-opentype'),
       url('AutoPro-Bold.woff') format('woff'),
       url('AutoPro-Bold.ttf') format('truetype'),
       url('AutoPro-Bold.svg#AutoPro-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
}
