.rtd a.embed-button
{
  display: inline-block;
  font: normal 16px/20px 'AutoPro-Regular', Arial;
  color: #fff;
  position: relative;
  height: 38px;
  margin-bottom: 15px;
  padding: 9px 35px 9px 10px;
  text-decoration: none;
  max-width: 100%;
  border-radius: 3px;
  transition: opacity 0.3s;
}
.rtd p.normal + a.embed-button
{
  margin-top: -20px;
}
.rtd a.embed-button > .fa
{
  display: inline-block;
  position: absolute;
  right: 10px;
  top: 50%;
  margin-top: -17px;
  font-size: 32px;
}
.rtd a.embed-button:hover
{
  opacity: 0.8;
}
