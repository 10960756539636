.embeddedobject-thuisprikroutes {
  background-color: #eef4f1;
  padding: 30px 20px;
  margin-bottom: 30px;
}
.embeddedobject-thuisprikroutes h2, .embeddedobject-thuisprikroutes p {
  margin-top: -5px;
  padding-bottom: 20px;
}
.embeddedobject-thuisprikroutes form {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.embeddedobject-thuisprikroutes input, .embeddedobject-thuisprikroutes button {
  display: block;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  border-radius: 0;
  font-size: 16px;
  padding: 9px 15px;
  line-height: 20px;
  height: 38px;
}
.embeddedobject-thuisprikroutes input {
  color: inherit;
  padding-right: 5px;
  flex: 1 1 auto;
  max-width: calc(100% - 200px);
}
.embeddedobject-thuisprikroutes button {
  flex: none;
  color: #fff;
  background-color: #59280b;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.embeddedobject-thuisprikroutes button i {
  margin-left: 10px;
  margin-right: -5px;
  flex: none;
  font-size: 32px;
}
@media (max-width: 460px) {
  .embeddedobject-thuisprikroutes {
    padding: 20px;
  }
  .embeddedobject-thuisprikroutes form {
    display: block;
  }
  .embeddedobject-thuisprikroutes form::after {
    display: block;
    content: "";
    clear: both;
  }
  .embeddedobject-thuisprikroutes input {
    margin-bottom: 20px;
    width: 100%;
    max-width: 100%;
  }
  .embeddedobject-thuisprikroutes button {
    float: right;
  }
}