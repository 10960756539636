.embeddedobject.cookiesettings
{
  border: 1px solid #6BBC22;
  padding: 15px;
  margin-bottom: 20px;
  margin-top: -10px;
}
.embeddedobject.cookiesettings input
{
  display: block;
  position: absolute;
  left: -9999px;
  width: 0;
  height: 0;
}
.embeddedobject.cookiesettings .cookiesettings__option
{
  margin-top: 7px;
}
.embeddedobject.cookiesettings label
{
  position: relative;
  display: flex;
  min-height: 28px;
  padding-left: 65px;
  align-items: center;
  cursor: pointer;
}
.embeddedobject.cookiesettings label::before
{
  content: "";
  background-color: #C9C9C9;
  height: 28px;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 14px;
  height: 28px;
  width: 53px;
  transition: background-color 0.2s;
}
.embeddedobject.cookiesettings label::after
{
  content: "";
  display: block;
  border-radius: 11px;
  position: absolute;
  left: 3px;
  width: 22px;
  height: 22px;
  background-color: #fff;
  top: 50%;
  margin-top: -11px;
  transition: left 0.2s;
}
.embeddedobject.cookiesettings input:checked + label::before
{
  background-color: #6CBC22;
}
.embeddedobject.cookiesettings input:checked + label::after
{
  left: 28px;
}

.embeddedobject.cookiesettings .cookiesettings__submit
{
  margin-top: 20px;
  display: inline-block;
  height: 38px;
  cursor: pointer;
  padding: 9px 20px;
  line-height: 20px;
  opacity: 1;
  transition: opacity 0.2s;
  background-color: #6CBC22;
  font: normal 19px/20px 'AutoPro-Regular', Arial;
  color: #fff;
}
