.embeddedobject.imageslist
{
  margin: 15px 0;
}
.embeddedobject.imageslist::after
{
  clear: both;
  content: '';
  display: block;
}
.embeddedobject.imageslist a
{
  display: inline-block;
  float: left;
  max-width: 100%;
}
.embeddedobject.imageslist a > img
{
  vertical-align: bottom;
  max-width: 100%;
}
