.embeddedobject.quicklinks
{
  margin: 15px 0;
}
.embeddedobject.quicklinks.mobile
{
  display: none;
}
.embeddedobject.quicklinks ul
{
  list-style-type: none;
  margin: 10px 0;
  padding: 0;
}
.embeddedobject.quicklinks ul > li
{
  position: relative;
}
.embeddedobject.quicklinks a
{
  display: inline-block;
  padding-left: 15px;
  line-height: 20px;
}
.embeddedobject.quicklinks .arrow
{
  display: inline-block;
  position: absolute;
  top: 3px;
  left: 0;
}
@media (max-width:920px)
{
  .embeddedobject.quicklinks.mobile
  {
    display: block;
  }
}
