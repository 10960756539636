/*! LOAD: wh.compat.stylereset, fonts.font-awesome
    USE: forms.css
    !*/


/* Defaults */
html
{
  font: 14px Arial, Sans-Serif;
  background-color: #fff;
  color: #59280b;
  height: 100%;
  min-height: 100%;
}

body
{
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
  -moz-text-size-adjust: none;
  text-size-adjust: none;
  overflow-x: hidden;
  overflow-y: scroll;
  min-height: 100%; position: relative;
}

p
{
  margin: 0;
  padding: 0;
}

/* need consent overlay */
.needconsentoverlay
{
  position: absolute;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;
  font-size: 13px;
  font-weight: bold;
  padding: 20px;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.6);
}
.needconsentoverlay a
{
  color: #fff !important;
  text-decoration: underline;
}
.needconsentoverlay > .fa
{
  font-size: 24px;
  display: block;
  width: 100%;
}

/* fix rte initial text size and color because rte ignores html/body css settings */
.wh-rtd-editor *
{
  font-size: 14px;
  font-family: Arial, Sans-Serif;
  color: #59280b;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
  .wh-rtd-editor .wh-rtd__table td
, .wh-rtd-editor .wh-rtd__table th
{
  vertical-align: top;
  padding-right: 5px;
}
.wh-rtd-editor .wh-rtd__table th
{
  background-color: #eee;
}

.wh-rtd__img
{
  max-width: 100%;
  height: auto;
}

sup
{
  vertical-align: top;
  line-height: 100%;
}
sub
{
  vertical-align: bottom;
  line-height: 100%;
}

h1.heading1
{
  font: normal 36px/38px 'AutoPro-Regular', Arial;
  margin-bottom: 25px;
}
h1.heading1--paddingtop
{
  padding-top: 55px;
}

h2.heading2
{
  font: normal 24px/26px 'AutoPro-Bold', Arial;
  margin-bottom: 5px;
}
h3.heading3
{
  font: normal 18px/20px 'AutoPro-Bold', Arial;
  margin-bottom: 5px;
}
p.normal
{
  line-height: 20px;
  margin-bottom: 20px;
}
p.normal img.fullwidth
{
  margin: 10px 0;
}
  ul.unordered
, ul.ordered
{
  margin-top: 0;
  margin-bottom: 20px;
}
p.normal + ul
{
  margin-top: -20px;
}
#maincontent > .pagedate
{
  display: block;
  margin-bottom: 15px;
}
#maincontent > h1 + .pagedate
{
  margin-top: -20px;
  margin-bottom: 30px;
}

p.introduction
{
  line-height: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}
.rtd a
{
  color: #59280b;
}

.wh-rtd__tablewrap
{
  max-width: 100%;
  overflow: auto;
}
.wh-rtd__table.tablemoney tr td + td
{
  text-align: right;
  padding-left: 5px;
}

#maincontent
{
  margin-bottom: 20px;
  width: 100%;
}
#maincontent::after
{
  clear: both;
  content: '';
  display: block;
}
#maincontent p.normal img
{
  max-width: 100%;
}
#maincontent p.normal img.fullwidth
{
  width: 100%;
  height: auto;
}


header
{
  height: 180px;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;/* so shadow is on top of image below */
}
header > #headerholder
{
  background-color: #eef4f1;
  position: absolute;
  height: 180px;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 0 3px 3px 0 rgba(100,100,100,0.2);
}
html.fixedheader header > #headerholder
{
  top: -95px;
  position: fixed;
}

header .inner
{
  max-width: 1020px;
  height: 100%;
  position: relative;
  margin: 0 auto;
  padding: 0 20px;
}

header .searchbar
{
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
}
header .searchbar > .inner
{
  max-width: 1020px;
  height: 0;
  position: relative;
  margin: 0 auto;
  padding: 0 20px;
}

  header .header__topbar
, header .header__bottombar
{
  display: flex;
  justify-content: space-between;
  padding: 20px 0 25px;
}

header .togglesearch
{
  display: inline-block;
  position: relative;
  height: 20px;
  color: #1E2328;
  font: normal 16px/20px 'AutoPro-Bold', Arial;
  padding-left: 26px;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s;
}
header .togglesearch > .fa
{
  position: absolute;
  height: 20px;
  top: 0;
  left: 0;
  font-size: 16px;
  color: #1E2328;
}
header .togglesearch:hover
{
  opacity: 0.7;
}

html.fixedheader header .togglesearch
{
  position: absolute;
  left: 20px;
  top: 129px;
  left: auto;
  background-position: 100% 50%;
  padding: 0 30px 0 0;
}
html.fixedheader header .togglesearch .fa
{
  left: auto;
  right: 0;
}

#topsearch
{
  position: absolute;
  height: 0;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  transition: height 0.3s;
  box-shadow: 0 3px 3px 0 rgba(100,100,100,0.2);
}
#topsearch.aftershow
{
  overflow: visible;
}

#topsearch .wh-autocomplete-values
{
  left: 20px;
  width: 250px;
}

@media(max-width: 920px)
{
  #topsearch .wh-autocomplete-values
  {
    left: 10px;
  }
}

html.fixedheader #topsearch
{
  top: 95px;
}

#topsearch.active
{
  height: 55px;
}
#topsearch .holder
{
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
#topsearch .inner
{
  max-width: 1020px;
  height: 55px;
  position: relative;
  margin: 0 auto;
  padding: 10px 20px;
}
  #topsearch .inner input
, #topsearch .inner button
{
  height: 35px;
  border: 0 none;
  -webkit-appearance: none;
  border-radius: 0;
  float: left;
}
#topsearch .inner input[type='search']
{
  background-color: #fff;
  width: 250px;
  color: #59280b;
  font-size: 13px;
  line-height: 15px;
  padding: 10px;
}
#topsearch .inner button
{
  cursor: pointer;
  padding: 0;
  margin: 0;
  width: 35px;
  background-color: #fff;
  color: #1E2328;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  font-size: 16px;
}
#topsearch .inner button::before
{
  position: relative;
  top: -1px;
}

@media (max-width:305px)
{
  #topsearch .inner input[type='search']
  {
    width: calc(100% - 35px);
  }
}

header .logo
{
  display: block;
  width: 187px;
  height: auto;
}
header .logo > img
{
  width: 100%;
  height: 100%;
}
html.fixedheader .logo
{
  display: none;
}

header #metanav
{
  white-space: nowrap;
}
header #metanav a
{
  color: #1E2328;
  height: 20px;
  font: normal 15px/18px 'AutoPro-Regular', Arial;
  text-decoration: none;
}
header #metanav a:hover
{
  text-decoration: underline;
}
header #metanav a + a
{
  margin-left: 15px;
}

header #sectionnav
{
  margin-top: 20px;
  position: relative;
  height: 30px;
  text-align: justify;
  overflow: hidden;
}
html.fixedheader .header__bottombar
{
  justify-content: flex-end;
  margin-top: 20px;
}

header #sectionnav > a + a
{
  margin-left: 30px;
}
header #sectionnav.alignleft
{
  text-align: left;
}
header #sectionnav.alignleft > a + a
{
  margin-left: 30px;
}

header #sectionnav > a
{
  color: #1E2328;
  text-transform: uppercase;
  display: inline-block;
  position: relative;
  font: 400 19px/20px 'AutoPro-Bold', Arial;
  letter-spacing: 1px;
  padding: 10px 0 0;
  letter-spacing: 0;
  text-decoration: none;
  opacity: 0.8;
  transition: opacity 0.3s;
}
header #sectionnav::after
{
  position: relative;
  display: inline-block;
  width: 100%;
  height: 0;
  line-height: 0;
  content: '';
}
header #sectionnav > a .line
{
  height: 4px;
  display: block;
  border-radius: 2px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: transparent;
  transition: background-color 0.3s;
}
  header #sectionnav > a.active
, header #sectionnav > a:hover
{
  opacity: 1;
}
  header #sectionnav > a.active .line
, header #sectionnav > a:hover .line
{
  background-color: #EC4126;
}

header #mobilemenu-open
{
  display: none;
  height: 37px;
  padding: 0 30px 0 0;
  cursor: pointer;
  position: absolute;
  font: normal 18px/37px 'AutoPro-Bold', Arial;
  color: #EC4126;
  bottom: 8px;
  right: 5px;
  transition: opacity 0.3s;
  opacity: 1;
}
header #mobilemenu-open .fa
{
  position: absolute;
  display: inline-block;
  height: 37px;
  font-size: 23px;
  top: 6px;
  right: 0;
}
header #mobilemenu-open:hover
{
  opacity: 0.7;
}

#mainholder
{
  min-height: 100%;
  padding: 0 0 335px;
}

  #mainholder > .inner
, #afterheader > .inner
, .fullwidth > .inner
{
  max-width: 1020px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
}

.exceptiondate
{
  font-weight: bold;
  padding: 15px 0;
}

#resultsfeedback
{
  font-weight: bold;
}
#afterheader
{
  margin: 0 auto;
  position: relative;
}
html.search #afterheader
{
  height: 160px;
}
@media (max-width:750px)
{
  html.search #afterheader
  {
    height: 100px;
  }
}

/* path */
#pathnav
{
  padding-top: 14px;
  width: 100%;
  height: 40px;
  margin: 10px 0 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font: 13px/15px Arial, Sans-Serif;
  color: #6cbc21;
}
#pathnav span
{
  display: inline-block;
  padding: 0 5px;
}
#pathnav a
{
  text-decoration: none;
  color: #6cbc21;
}
  html.search #pathnav
, html.search #pathnav a
{
  color: #fff;
}
#pathnav a:hover
{
  text-decoration: underline;
}

/* searchpage */
#searchpageform
{
  margin-top: -60px;
  padding-bottom: 60px;
}
#searchpageform .wh-autocomplete-values
{
  top: 38px;
  left: 20px;
  right: 140px;
}

  #searchpageform > input
, #searchpageform > button
{
  text-align: left;
  border: 0 none;
  border-radius: 0;
  -webkit-appearance: none;
  float: left;
  height: 38px;
  font-size: 17px;
  padding: 5px 10px;
  line-height: 28px;
}
#searchpageform > input[name='words']
{
  width: calc(100% - 120px);
}
#searchpageform > button
{
  width: 120px;
  color: #fff;
  position: relative;
  cursor: pointer;
}
#searchpageform > button > span.fa
{
  position: absolute;
  top: 10px;
  right: 10px;
}
#searchresults
{
  padding-top: 15px;
  font: 14px Arial, Sans-Serif;
}

#searchresults .feedback
{
  margin-bottom: 5px;
}
#searchresults .feedback .count
{
  display: block;
  margin-bottom: 15px;
}
#searchresults ul
{
  list-style-type: none;
  margin: 0;
  padding: 0;
}
#searchresults .results a
{
  color: #59280b;
  text-decoration: none;
  display: block;
  padding: 15px 0;
}
#searchresults .results
{
  border-top-width: 1px;
  border-top-style: solid;
  margin-bottom: 10px;
}
#searchresults .results li
{
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
#searchresults .results .title
{
  display: block;
  padding-bottom: 5px;
  font-size: 17px;
}
#searchresults .results .summary
{
  display: block;
  line-height: 20px;
}
#searchresults .results .path
{
  margin-top: 3px;
  display: block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* pagination */
.pagination
{
  text-align: left;
  list-style-type: none;
  margin: 5px 0;
  padding: 0;
}
.pagination li
{
  display: inline-block;
}
.pagination li a
{
  text-decoration: none;
  color: #59280b;
  display: inline-block;
  padding: 3px 5px;
  min-width: 15px;
  text-align: center;
}
  .pagination li a:hover
, .pagination li.active a
{
  background-color: #6cbc21;
  color: #fff;
}


.twocol > main
{
  width: 100%;
  display: table-cell;
  vertical-align: top;
}
#rightcol
{
  width: 255px;
  display: table-cell;
  vertical-align: top;
}
#rightcol.shiftup
{
  width: 305px;
}
#rightcol > .inner
{
  width: 255px;
  padding-left: 25px;
}
#rightcol.shiftup > .inner
{
  width: 363px;
  padding: 25px;
  margin-right: -25px;
  margin-top: -290px;
  background-color: #fff;
}
@media (max-width:1020px)
{
    #rightcol
  , #rightcol > .inner
  {
    width: calc(25vw - 5px);
  }

    #rightcol.shiftup
  , #rightcol.shiftup > .inner
  {
    width: calc(33.33vw + 20px);
  }
}
@media (max-width:920px)
{
    #rightcol
  , #rightcol > .inner
  {
    width: auto;
    margin-bottom: 20px;
  }

    #rightcol.shiftup
  , #rightcol.shiftup > .inner
  {
    width: auto;
  }
}


#sharepage
{
  max-width: 1020px;
  font: normal 18px/34px 'AutoPro-Bold', Arial;
  margin: 0 auto 20px;
  padding: 0 20px;
}
#sharepage::after
{
  clear: both;
  content: '';
  display: block;
}
#sharepage > *
{
  display: inline-block;
  height: 34px;
  float: left;
}
#sharepage .share
{
  margin-left: 10px;
  width: 34px;
  height: 34px;
  border-radius: 17px;
  transition: background-color 0.3s;
  cursor: pointer;
}
html:not([data-wh-consent*="thirdparty"]) #sharepage
{
  color: #ccc;
  opacity: 0.5;
}
html:not([data-wh-consent*="thirdparty"]) #sharepage > img.share
{
  cursor: default;
  background-color: #ccc;
}


/* */
#sidenav
{
  margin-bottom: 20px;
}

.menu
{
  background-color: #eef4f1;
}
.menu a
{
  text-decoration: none;
  font-size: 13px;
  line-height: 18px;
  padding: 7px 0 5px 28px;
  color: #59280b;
  display: block;
  transition: background-color 0.3s, color 0.3s;
}
.menu ul
{
  border-top: 1px solid #c0bfc1;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.menu > ul
{
  border-bottom: 1px solid #c0bfc1;
}
.menu ul > li.path > a
{
  background: transparent url(../web/img/arrow-left-brown.png) 7px 50% no-repeat;
}
  .menu ul > li.path > a:hover
, .menu ul > li.path > a:active
, .menu ul > li.path.active > a
{
  background-image: url(../web/img/arrow-left-white.png);
}
.menu ul > li.haschildren > a
{
  background-image: url(../web/img/arrow-right-green.png);
  background-position: 7px 50%;
  background-repeat: no-repeat;
}
  .menu > ul > li.haschildren.active > a
, .menu > ul > li.haschildren > a:hover
{
  background-image: url(../web/img/arrow-right-white.png);
}
  .menu ul > li.foldout.haschildren > a
, .menu div.level0 > ul > li.foldout.haschildren > a
{
  background-image: url(../web/img/arrow-down-green.png);
}
  .menu > ul > li.foldout.haschildren.active > a
, .menu > ul > li.foldout.haschildren > a:hover
{
  background-image: url(../web/img/arrow-down-white.png);
}
.menu li
{
  min-height: 30px;
}
.menu li.haschildren > .childscontainer
{
  height: 0;
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.4s;
}
.menu li.haschildren.foldout > .childscontainer
{
  height: auto;
  opacity: 1;
}
.menu ul > li + li
{
  border-top: 1px solid #c0bfc1;
}
  .menu > ul > li > a:hover
, .menu > ul > li.active > a
{
  background-color: #6cbc21;
  color: #fff;
}
.menu > ul > li > a:active
{
  background-color: #56961a;
  color: #fff;
}
.menu li > div > ul > li a
{
  background-color: #fff;
}
  .menu li > div > ul > li > a:hover
, .menu li > div > ul > li.active > a
{
  background-color: #c4e4a6;
}
.menu li > div > ul > li > a:active
{
  background-color: #6cbc21;
}

.menu .section > a
{
  font-weight: bold;
}
  #sidenav.menu div.level2 > ul > li > a
, #mobilemenu .menu div.level1 > ul > li > a
{
  padding-left: 38px;
}
#mobilemenu .menu div.level2 > ul > li > a
{
  padding-left: 48px;
}


ul.linklist
{
  list-style-type: none;
  margin: 0;
  padding: 0;
}
ul.linklist > li
{
  padding-left: 18px;
  background: transparent url(../web/img/arrow-right-white.png) 0 3px no-repeat;
}
ul.linklist > li + li
{
  margin-top: 10px;
}
ul.linklist li a
{
  color: #6f320e;
  text-decoration: none;
  font: normal 14px/18px 'AutoPro-Bold', Arial;
}
ul.linklist li a:hover
{
  text-decoration: underline;
}
#mobilemenu ul.linklist + ul.linklist
{
  margin-top: 10px;
}
footer
{
  height: 315px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  color: #fff;
  font-size: 13px;
  line-height: 16px;
}
footer > .inner
{
  max-width: 1020px;
  height: 100%;
  position: relative;
  margin: 0 auto;
  padding: 20px;
}
footer > .inner::after
{
  clear: both;
  content: '';
  display: block;
}
  .footer-quicklinks .title
, .footer-contact .title
{
  font: normal 18px/20px 'AutoPro-Bold', Arial;
  color: #fff;
  margin-bottom: 10px;
  min-width: 230px;
}
footer .footer-quicklinks
{
  display: table-cell;
  width: 100%;
  vertical-align: top;
}
footer .footer-quicklinks .linklistgroup
{
  display: table;
  width: calc(100% - 80px);
}
footer .footer-quicklinks ul.linklist
{
  display: table-cell;
  vertical-align: top;
  padding-right: 15px;
  width: 50%;
}

footer .footer-contact
{
  display: table-cell;
  width: 230px;
  vertical-align: top;
}
.footer-contact
{
  color: #6f320e;
}
.footer-contact .tel
{
  display: inline-block;
  font: normal 24px/26px 'AutoPro-Bold', Arial;
  margin-bottom: 15px;
}
.footer-contact .tel a
{
  color: #6f320e;
  text-decoration: none;
}
.footer-contact .more
{
  margin-top: 15px;
}

footer .bottom
{
  border-top: 1px solid #fff;
  height: 130px;
  position: absolute;
  bottom: 0;
  left: 20px;
  right: 20px;
}
footer .bottom .slogan
{
  width: 250px;
  width: 236px;
  height: 22px;
  font: normal 24px/30px 'AutoPro-Bold', Arial;
  font-style: italic;
}
footer .bottom #bottomnav
{
  position: absolute;
  width: 360px;
  left: 50%;
  top: 15px;
  margin-left: -180px;
  text-align: center;
  white-space: nowrap;
}
footer .bottom #bottomnav .socialmedia
{
  margin-top: 10px;
  margin-bottom: 10px;
  height: 38px;
}
footer .bottom #bottomnav .socialmedia a
{
  display: inline-block;
  width: 38px;
  height: 38px;
  opacity: 1;
  transition: opacity 0.3s;
}
footer .bottom #bottomnav .socialmedia a:hover
{
  opacity: 0.6;
}
footer .bottom .peilers
{
  width: 230px;
  position: absolute;
  top: 58px;
  right: 0;
  text-transform: uppercase;
  font: normal 13px/20px 'AutoPro-Bold', Arial;
  letter-spacing: 1px;
}

.footernav
{
  color: #fff;
  font-size: 13px;
  line-height: 16px;
}
.footernav .separator
{
  display: inline-block;
  padding: 0 5px;
}
.footernav a
{
  color: #fff;
  text-decoration: none;
  font-size: 13px;
}
.footernav a:hover
{
  text-decoration: underline;
}
.bottompartof
{
  width:230px;
  position:absolute;
  left:0;
  top:58px;
  white-space:nowrap;
}
.bottompartof__sloganbase
{
  vertical-align:middle;
}
.bottompartof__logo
{
  height:50px;
  width:180px;
  vertical-align:middle;
}


  #mobilemenuholder
, #mobilemenuholder .overlay
{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: 2;
}
#mobilemenuholder
{
  display: none;
}
#mobilemenuholder .overlay
{
  background-color: #000;
  opacity: 0;
  transition: opacity 0.3s;
}
html.showmenu #mobilemenuholder .overlay
{
  opacity: 0.7;
}
#mobilemenu
{
  width: 265px;
  position: fixed;
  top: 0;
  right: -265px;
  bottom: 0;
  background-color: #81aa94;
  overflow: hidden;
  overflow-y: auto;
  transition: right 0.3s;
  z-index: 3;
}
html.showmenu #mobilemenu
{
  right: 0;
}
#mobilemenu .logo
{
  display: block;
  width: 265px;
  height: 110px;
  background-color: #fff;
}
#mobilemenu .logo > img
{
  margin: 40px 0 0 30px;
  width: 165px;
  height: auto;
}
#mobilemenu-close
{
  width: 39px;
  height: 39px;
  padding: 10px;
  position: absolute;
  display: block;
  top: 65px;
  right: 5px;
  cursor: pointer;
}
#mobilemenu-close .fa
{
  font-size: 33px;
  color: #EC4126;
  margin-left: -4px;
  margin-top: -5px;
}
#mobilemenu-close .fa + .fa
{
  margin-left: 4px;
}


  #mobilemenu .footer-quicklinks
, #mobilemenu .footer-contact
, #mobilemenu .footernav
{
  padding: 25px 25px 0;
}
#mobilemenu .footernav
{
  padding-bottom: 15px;
}
#mobilemenu .footer-quicklinks
{
  padding-bottom: 10px;
}
#mobilemenu .footer-quicklinks + .footer-contact
{
  border-top: 1px solid #fff;
}
#mobilemenu .footernav .copyright
{
  display: block;
  padding-bottom: 5px;
}
#mobilemenu .footernav .copyright + .separator
{
  display: none;
}

@media (max-width:920px)
{
/* hide items: */
    #metanav, #sectionnav
  , #sidenav
  , footer
  {
    display: none;
  }

  .twocol > main, #rightcol
  {
    display: block;
    width: 100%;
  }
  #rightcol.shiftup > .inner
  {
    padding: 0;
    margin: 0;
    background-color: transparent;
  }
  #rightcol > .inner
  {
    width: 100%;
    padding-left: 0;
    margin-bottom: 25px;
  }

  #mainholder
  {
    padding-bottom: 15px;
  }

/* smaller header and logo */
  header, header > #headerholder
  {
    height: 110px;
  }
  html.fixedheader header > #headerholder
  {
    top: -40px;
    position: fixed;
  }
  header .logo
  {
    position: absolute;
    bottom:  7px;
    left: 50%;
    transform: translateX(-50%);
    width: 140px;
  }

  html.fixedheader header .logo
  {
    display: block;
  }

  header .togglesearch
  {
    position: absolute;
    left: 10px;
    top: auto;
    bottom: 16px;
  }
  html.fixedheader header .togglesearch
  {
    left: 10px;
    right: auto;
    top: auto;
    background-position: 0% 50%;
    padding: 0 0 0 26px;
  }
  html.fixedheader header .togglesearch .fa
  {
    left: 0;
    right: auto;
  }
  #topsearch .inner
  {
    padding: 10px;
  }

  header .searchbar
  {
    top: 110px;
    z-index: 100;
  }

/* visible items: */
  header #mobilemenu-open
  {
    display: inline-block;
  }
}


@media (max-width:380px)
{
    header .togglesearch > span:not(.fa)
  , header #mobilemenu-open > span:not(.fa)
  {
    display: none;
  }
}

/* summary */
#summary
{
  list-style-type: none;
  margin: 0;
  padding: 0;
  border-top: 1px solid #6cbc21;
}
#summary li
{
  border-bottom: 1px solid #6cbc21;
  opacity: 1;
  transition: opacity 0.3s;
}
#summary li a
{
  display: block;
  padding: 15px 5px;
  text-decoration: none;
  color: #59280b;
  line-height: 18px;
  transition: background-color 0.3s;
}
#summary li a::after
{
  clear: both;
  display: block;
  content: '';
}
#summary li a:hover
{
  background-color: #f8ffeb;
}
#summary li a .date
{
  display: block;
  color: #6cbc21;
  font-size: 13px;
}
#summary li a strong
{
  display: block;
  font: normal 18px/20px 'AutoPro-Bold', Arial;
  margin-bottom: 5px;
}
#summary li a > img
{
  float: right;
  margin: 0 -5px 0 20px;
  width: 170px;
  height: auto;
}
@media (max-width:600px)
{
  #summary li a
  {
    padding: 10px 5px;
  }
  #summary li a > img
  {
    margin-left: 10px;
    width: 100px;
  }
}
@media (max-width:400px)
{
  #summary li a > img
  {
    width: 60px;
  }
}
#summary_bottom
{
  height: 40px;
  padding: 10px 10px;
  color: #fff;
  cursor: pointer;
  font: normal 18px/20px 'AutoPro-Regular', Arial;
}
#summary_bottom > .arrow
{
  position: relative;
  top: 3px;
  margin: 0 0 0 10px;
  display: inline-block;
  border-top: 15px solid #fff;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
}

/* pictures */
#pictures
{
  position: relative;
  background-color: #eef4f1;
  margin-bottom: 20px;
}
#pictures .wh-slideshow
{
  position: relative;
  width: 100%;
  overflow: hidden;
}
#pictures .wh-slideshow::after
{
  display: block;
  content: '';
  padding-top: 75%;
}
#pictures .wh-slideshow-item
{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  overflow: hidden;
}
#pictures .wh-slideshow-item > img
{
  height: 100%;
  width: auto;
}
  #pictures .previous
, #pictures .next
{
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  margin-top: -20px;
  background-color: #666;
  color: #fff;
  cursor: pointer;
  font-size: 28px;
  text-align: center;
  line-height: 40px;
  transition: padding 0.3s, opacity 0.3s;
}
  #pictures .previous.disabled
, #pictures .next.disabled
{
  cursor: default;
  opacity: 0;
}
#pictures .previous
{
  left: 10px;
  padding-right: 4px;
}
#pictures .previous:hover
{
  padding-right: 7px;
}
#pictures .next
{
  right: 10px;
  padding-left: 4px;
}
#pictures .next:hover
{
  padding-left: 7px;
}
#picturesinfo
{
  background-color: #6cbc21;
  position: relative;
  height: 35px;
  color: #fff;
  padding: 5px 15px 5px 15px;
  font: normal 17px/23px 'AutoPro-Regular', Arial;
}
#picturesinfo > *
{
  opacity: 1;
  transition: opacity 0.2s;
}
#picturesinfo .title
{
  display: block;
  max-width: 100%;
  padding-right: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#picturesinfo .nr
{
  opacity: 1;
  display: inline-block;
  position: absolute;
  top: 5px;
  right: 15px;
  height: 35px;
}
#picturesinfo.hide > *
{
  opacity: 0;
}

/* widgets */
html.widgetpreview #widgets
{
  max-width: 370px;
  margin: 0 auto;
  padding: 20px;
}
.widgetsholder
{
  max-width: 1020px;
  padding: 0 20px;
  margin: 0 auto 20px;
}
.widgetsholder #widgets
{
  margin-left: -20px;
  position: relative;
}
.widgetsholder #widgets::after
{
  clear: both;
  content: '';
  display: block;
}
.widgetsholder #widgets .widget
{
  float: left;
  width: 25%;
  padding-left: 20px;
  padding-bottom: 20px;
}
main .widgetsholder
{
  padding: 0;
}

.widgetsholder #widgets.threecolmax .widget
{
  width: 33.33%;
}
.widgetsright::after
{
  clear: both;
  content: '';
  display: block;
}
#rightcol .widget
{
  padding-top: 25px;
}
@media (max-width:920px)
{
  .widgetsright
  {
    margin-left: -20px;
  }
  #rightcol .widget
  {
    width: 50%;
    float: left;
    padding-left: 20px;
  }
}
@media (max-width:750px)
{
    .widgetsholder #widgets .widget
  , .widgetsholder #widgets.threecolmax .widget
  {
    width: 50%;
  }
}
@media (max-width:500px)
{
    .widgetsholder #widgets .widget
  , .widgetsholder #widgets.threecolmax .widget
  {
    width: 100%;
  }

  .widgetsright
  {
    margin-left: 0;
  }
  #rightcol .widget
  {
    width: 100%%;
    float: none;
    padding-left: 0;
  }

}
.widget > .inner
{
  position: relative;
  box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.3);
  font-size: 13px;
  color: #fff;
  border-color: #fff;
}
.widget > inner.fullbgcolor a
{
  color: #fff;
}
.widget .title
{
  font: normal 17px/20px 'AutoPro-Bold', Arial;
  padding: 10px;
}
.widget .title.titleontopmedia
{
  position: absolute;
  top: 0;
  left: 0;
  right: auto;
  max-width: 100%;
  display: inline-block;
  color: #fff;
  padding: 5px 10px;
}

.wh-popup-chrome
{
  width: 80%;
}
.wh-modalitylayer
{
  background-color: rgba(0,0,0,0.5);
  z-index: 100;
}
.wh-popup-container
{
  z-index: 101;
}

.wh-popup-theme-medlon_message
{
  max-width: 320px;
  position: relative;
}
.wh-popup-theme-medlon_message.wh-popup-chrome
{
  background-color: #fff;
  display: inline-block;
  border: 1px solid #59280b;
  padding: 30px 20px 10px;
}
.wh-popup-theme-medlon_message. .wh-popup-body-container
{
  max-width: 320px;
  min-height: 100px;
}
.wh-popup-theme-medlon_message .close
{
  position: absolute;
  width: 20px;
  height: 20px;
  cursor: pointer;
  font-size: 20px;
  right: 10px;
  top: 0px;
}
.wh-popup-theme-medlon_message .close > .fa + .fa
{
  margin-left: 4px;
}
.wh-popup-theme-medlon_message h2
{
  margin-bottom: 10px;
  color: #7eaa95;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

#videodialog
{
  width: 100%;
  max-width: 1080px;
  margin: 0 auto;
}
@media (max-width:640px)
{
  #videodialog, .wh-popup-chrome
  {
    width: 100%;
  }
}
#videodialog iframe
{
  top: 0;
  left: 0;
}

  .widget .video
, #videodialog .video
, .embeddedobject.video .video
{
  position: relative;
  width: 100%;
  background-color: #fff;
  overflow: hidden;
}

  html[data-wh-consent*="thirdparty"] .widget .video
, html[data-wh-consent*="thirdparty"] #videodialog .video
, html[data-wh-consent*="thirdparty"] .embeddedobject.video .video
{
  cursor: pointer;
}

  .widget .video::before
, #videodialog .video::before
, .embeddedobject.video .video::before
{
  display: block;
  content: '';
  padding-top: 56.25%;
}
  .widget .video > img.preview
, #videodialog .video > img.preview
, .embeddedobject.video .video > img.preview
{
  top: 0;
  left: 0;
  width: 100%;
  position: absolute;
}
  .widget .video > .play
, #videodialog .video > .play
, .embeddedobject.video .video > .play
{
  width: 42px;
  height: 42px;
  background-color: rgba(255,255,255,0.9);
  border-radius: 21px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -21px 0 0 -21px;
  transition: background-color 0.3s;
  display: none;
}
  html[data-wh-consent*="thirdparty"] .widget .video > .play
, html[data-wh-consent*="thirdparty"] #videodialog .video > .play
, html[data-wh-consent*="thirdparty"] .embeddedobject.video .video > .play
{
  display: block;
}

  .widget .video:hover > .play
, #videodialog .video:hover > .play
, .embeddedobject.video .video:hover > .play
{
  background-color: rgba(255,255,255,1);
}
  .widget .video > .play > .arrow
, #videodialog .video > .play > .arrow
, .embeddedobject.video .video > .play > .arrow
{
  border-top: 9px solid transparent;
  border-left: 16px solid #000;
  border-bottom: 9px solid transparent;
  position: absolute;
  top: 12px;
  left: 15px;
}

.widget .image
{
  width: 100%;
  height: auto;
}
/* prevent whitespace below image */
.widget img.image
{
  vertical-align: bottom;
}
.widget .rte
{
  padding: 10px;
  color: #59280b;
}
  .widget > .inner.fullbgcolor .rte
, .widget > .inner.fullbgcolor .rte a
{
  color: #fff;
}
.widget .rte a
{
  text-decoration: none;
}
  .widget .rte a:hover
, .widget > .inner.fullbgcolor .rte a
{
  text-decoration: underline;
}
.widget .rte + .button
{
  margin-top: 5px;
}
.widget .button
{
  height: 30px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
  line-height: 20px;
  text-decoration: none;
  padding: 5px 40px 5px 10px;
  display: block;
  position: relative;
  color: #fff;
  transition: color 0.3s, background-color 0.3s;
}
.widget .button > .arrow
{
  position: absolute;
  width: 30px;
  height: 30px;
  top: 0;
  bottom: 0;
  right: 0;
  color: #fff;
  padding: 5px 5px 5px 8px;
  text-align: center;
  font-weight: normal;
  line-height: 20px;
  font-size: 20px;
}
.widget .button:hover
{
  color: #fff;
}
.widget .button > .arrow > .gap
{
  position: absolute;
  top: 0;
  left: 0;
  border-top: 5px solid transparent;
  border-left-width: 5px;
  border-left-style: solid;
  border-bottom: 5px solid transparent;
}
.widget .button:hover > .arrow > .gap
{
  display: none;
}

.widget ul.links
{
  list-style-type: none;
  padding: 10px 10px 15px;
  margin: 0;
}
.widget ul.links li
{
  padding: 0;
}
.widget ul.links li + li
{
  margin-top: 10px;
}
.widget ul.links a
{
  color: #59280b;
  display: block;
  text-decoration: none;
  padding-left: 15px;
  position: relative;
  line-height: 17px;
}
.widget ul.links a > .arrow
{
  position: absolute;
  top: -1px;
  left: 0;
  font-weight: bold;
  font-size: 19px;
}
.widget ul.links strong
{
  display: block;
  margin-bottom: 3px;
  font-size: 14px;
}
.widget ul.links .description
{
  font-size: 13px;
}
.widget-news .links a:hover
{
  text-decoration: underline;
}

/* exception widget bgyellow */
.widget > .inner.bgyellow .rte
{
  color: #6f320e;
}
.widget .inner.bgyellow .rte a
{
  text-decoration: none;
}
.widget .inner.bgyellow .rte a:hover
{
  text-decoration: underline;
}



/* message */
#globalmessage_holder
{
  display: block;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 0;
  background: #EEF4F1;
  box-shadow: 0 2px 7px 7px rgba(0,0,0,0.19);
  z-index: 22000;
}
#globalmessage_inner
{
  max-width:1010px;
  margin:0 auto;
  position:relative;
}
#globalmessage_close
{
  display:inline-block;
  position:absolute;
  top: 10px;
  right: 20px;
  height: 29px;
  line-height: 29px;
  cursor:pointer;
  padding: 0 37px 0 0;
  background: transparent url(../web/img/button_close.png) 100% 50% no-repeat;
}
#globalmessage_inner > .globalmessage
{
  max-width: 1020px;
  margin: 0 auto;
  padding: 20px 275px 10px 20px;

  font-size: 14px;
  line-height: 20px;
}
@media(max-width: 920px)
{
  #globalmessage_inner > .globalmessage
  {
    padding-right: 20px;
  }
}
#globalmessage_holder.scrollable
{
  overflow-y: auto;
}
@media (max-width:640px)
{
  #globalmessage_inner > .globalmessage
  {
    padding: 50px 20px 10px 20px;
  }
}
.globalmessage .title
{
  font-size: 15px;
  display: block;
}
.globalmessage--cookiemessage .title
{
  font: normal 36px/38px 'AutoPro-Regular', Arial;
  padding-bottom: 5px;
}
.globalmessage--cookiemessage .buttons
{
  padding: 10px 0;
}
.globalmessage--cookiemessage .button
{
  display: inline-block;
  height: 38px;
  cursor: pointer;
  padding: 9px 20px;
  line-height: 20px;
  opacity: 1;
  transition: opacity 0.2s;
}
.globalmessage--cookiemessage .button:hover
{
  opacity: 0.8;
}
.globalmessage--cookiemessage .button[data-action='accept']
{
  background-color: #6CBC22;
  font: normal 19px/20px 'AutoPro-Regular', Arial;
  color: #fff;
}
.globalmessage--cookiemessage a.button
{
  color: inherit;
  text-decoration: underline;
}
.globalmessage--cookiemessage .button + .button
{
  margin-left: 10px;
}

#globalmessage_inner .title + div
{
  padding-bottom: 10px;
}
.globalmessage + .globalmessage
{
  border-top: 1px solid #fff;
  padding-top: 10px;
}
#globalmessage_inner p
{
  margin:3px 0 0;
}
#globalmessage_inner p > *
{
  font: inherit !important;
}
#globalmessage_inner p a
{
  text-decoration: underline;
  color: inherit;
  font-weight:bold;
}


/* accordion */
.wh-accordion-group
{
  border-top: 1px solid #6cbc21;
  border-bottom: 1px solid #6cbc21;
  margin-top: 10px;
  position: relative;
  overflow: hidden;
  min-height: 41px;
  transition: height 0.3s;
}
.wh-accordion-group + .wh-accordion-group
{
  margin-top: 0;
  border-top: 0 none;
}
.wh-accordion-question
{
  padding: 8px 35px 7px 0;
  margin: 0;
  display: block;
  cursor: pointer;
  background-color: #fff;
  font: normal 17px/20px 'AutoPro-Bold', Arial;
  position: relative;
}
.wh-accordion-group h2
{
  transition: color 0.3s;
}
.wh-accordion-group.active h2
{
  color: #6cbc21;
}
.wh-accordion-question .arrow
{
  display: block;
  width: 15px;
  height: 15px;
  position: absolute;
  top: 50%;
  margin-top: -12px;
  right: 9px;
}
  .wh-accordion-group.active .wh-accordion-question .arrow
, .wh-accordion-question .arrow.down
, .wh-accordion-group.active .wh-accordion-question .arrow
{
  display: none;
}
.wh-accordion-group.active .wh-accordion-question .arrow.down
{
  display: block;
}
.wh-accordion-anwer
{
  display: none;
  /* padding: 5px 80px 10px 0; */
  padding: 5px 0 10px 0;
}
.wh-accordion-anwer::after
{
  clear: both;
  display: block;
  content: '';
}

/* referencedata */
#referencedata
{
  list-style-type: none;
  margin:0;
  padding:0;
  position:relative;
  border-top-width: 1px;
  border-top-style: solid;
}
#referencedata li
{
  margin-bottom:3px;
  position:relative;
  display:block;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
#referencedata li a
{
  text-decoration: none;
  display: block;
  height: 50px;
  width: 100%;
  color: #59280b;
  line-height: 50px;
  text-indent: 20px;
  white-space: nowrap;
  font-size: 18px;
  font-family: "AutoPro-Bold", Arial,Helvetica,Sans-Serif;
  position: relative;
  transition: color 0.3s;
}
  #referencedata li a:hover
, #referencedata li a:active
{
  color: #6cbc21;
}
#referencedata li .arrowright
{
  display: inline-block;
  float: right;
  line-height: 48px;
  font-size: 24px;
}

/* new window with reference table */
html.referencetable .contentblock
{
  height: 75px;
  margin: 10px 0 30px 0;
  background-color: #9bb5a8;
  color: #fff;
  position: relative;
  padding: 20px;
}
html.referencetable .contentblock h1
{
  color: #fff;
  margin: 0;
}
html.referencetable #em_searchform
{
  display: block;
  margin-top: 20px;
  max-width: 520px;
}
html.referencetable #em_searchform #em_terms
{
  height:20px;
  width:100%;
  display:block;
  text-indent: 5px;
  border:0px none;
  color:#6f320e;
  font-size:11px;
  line-height:20px;
}
html.referencetable #em_submitholder
{
  position:absolute;
  bottom:-25px;
  left:0px;
  height:24px;
  background-color: white;
  width:560px;
}
html.referencetable #em_submitholder #em_submit
{
  height:20px;
  margin-top:4px;
  color:white;
  border:0px none;
  width:540px;
  text-align:right;
  line-height:16px;
  font-size:11px;
  font-weight:bold;
  padding-right:25px;
  cursor:pointer;
  background: transparent url(../web/img/bg_btn.png) no-repeat 100% -30px;
}
/* triangle gap on left side(s) */
html.referencetable .contentblock .arrow
{
  display:block;
  width:14px;
  height:27px;
  background: transparent url(../web/img/arrows.png) 0px -30px no-repeat;
  position:absolute;
  left:0px;
  top:0px;
}

html.referencetable body
{
  background: #fff none;
  overflow: auto;
  margin: 0;
  padding: 0;
  height: auto;
}
html.referencetable main
{
  width: auto;
  display: block;
  padding: 0 10px;
}
html.referencetable table
{
  border-collapse: collapse;
  background-color:#f6f9f8;
  width:100%;
  margin-bottom:10px;
  max-width: 1024px;
}
html.referencetable td, body.referencetable th
{
  padding:4px 10px 4px 15px;
  font-size:12px;
  text-align:left;
  vertical-align: top;
}
html.referencetable tr.odd td
{
  background-color:#e9efed;
}
html.referencetable th
{
  background-color: #c3d3cb;
  color:#8ba598;
  font-size:13px;
  padding:0px 10px 0px 15px;
  height:23px;
  text-align: left;
  line-height:23px;
}
html.referencetable .contentblock
{
  height:75px;
  margin:10px 0 30px;
}
html.referencetable #em_submitholder
{
  bottom:-25px;
}


/* embeddedobjects */

.embeddedobject.video
{
  margin-bottom: 20px;
}


/* Sitemap */
.sitemap
{
  margin: 20px 0;
}
.sitemap ul
{
  margin: 0;
  padding: 0 0 0 20px;
}
.sitemap ul > li
{
  padding: 2px 0;
}
.sitemap a
{
  color: #59280b;
  line-height: 18px;
}


/* colours */
  .bggreygreen
, .bggreygreen.bghoverlight:hover
, .bggreygreen:hover .bghoverlight, .bghovergreygreen:hover
{ background-color: #81aa94; }

.bggreygreen80
{ background-color: rgba(129,170,148,0.8); }
.bggreygreen90
{ background-color: rgba(129,170,148,0.9); }

.fggreygreen, .afggreygreen a, .afggreygreen p.normal a, .widget .button.fggreygreen
{ color: #81aa94; }

.bordergreygreen
{ border-color: #81aa94; }

  .bggreygreen.bgdark
, .bggreygreen > .bgdark
, .bggreygreen.bghoverdark:hover
, .bggreygreen:hover > .bghoverdark, .bgdarkhovergreygreen:hover
{ background-color: #678876; }

  .bgbrown
, .bgbrown.bghoverlight:hover
, .bgbrown:hover .bghoverlight, .bghoverbrown:hover
{ background-color: #6f320e; }

.bgbrown80
{ background-color: rgba(111,50,14,0.8) }
.bgbrown90
{ background-color: rgba(111,50,14,0.9) }

.fgbrown, .afgbrown a, .afgbrown p.normal a, .widget .button.fgbrown
{ color: #6f320e; }

.borderbrown
{ border-color: #6f320e; }

  .bgbrown.bgdark
, .bgbrown > .bgdark
, .bgbrown.bghoverdark:hover
, .bgbrown:hover > .bghoverdark, .bgdarkhoverbrown:hover
{ background-color: #59280b; }

  .bggreen
, .bggreen.bghoverlight:hover
, .bggreen:hover .bghoverlight, .bghovergreen:hover
{ background-color: #6cbc21; }

.bggreen80
{ background-color: rgba(108,188,33,0.8) }
.bggreen90
{ background-color: rgba(108,188,33,0.9) }

.fggreen, .afggreen a, .afggreen p.normal a, .widget .button.fggreen
{ color: #6cbc21; }

.bordergreen
{ border-color: #6cbc21; }

  .bggreen.bgdark
, .bggreen > .bgdark
, .bggreen.bghoverdark:hover
, .bggreen:hover > .bghoverdark, .bgdarkhovergreen:hover
{ background-color: #56961a; }

  .bgviolet
, .bgviolet.bghoverlight:hover
, .bgviolet:hover .bghoverlight, .bghoverviolet:hover
{ background-color: #5c2298; }

.bgviolet80
{ background-color: rgba(92,34,152,0.8) }
.bgviolet90
{ background-color: rgba(92,34,152,0.9) }

.fgviolet, .afgviolet a, .afgviolet p.normal a, .widget .button.fgviolet
{ color: #5c2298; }

.borderviolet
{ border-color: #5c2298; }

  .bgviolet.bgdark
, .bgviolet > .bgdark
, .bgviolet.bghoverdark:hover
, .bgviolet:hover > .bghoverdark, .bgdarkhoverviolet:hover
{ background-color: #4a1b7a; }

  .bgmagenta
, .bgmagenta.bghoverlight:hover
, .bgmagenta:hover .bghoverlight, .bghovermagenta:hover
{ background-color: #ae0188; }

.bgmagenta80
{ background-color: rgba(174,1,136,0.8) }
.bgmagenta90
{ background-color: rgba(174,1,136,0.9) }

.fgmagenta, .afgmagenta a, .afgmagenta p.normal a, .widget .button.fgmagenta
{ color: #ae0188; }

.bordermagenta
{ border-color: #ae0188; }

  .bgmagenta.bgdark
, .bgmagenta > .bgdark
, .bgmagenta.bghoverdark:hover
, .bgmagenta:hover > .bghoverdark, .bgdarkhovermagenta:hover
{ background-color: #8b016d; }

  .bgpink
, .bgpink.bghoverlight:hover
, .bgpink:hover .bghoverlight, .bghoverpink:hover
{ background-color: #e6007e; }

.bgpink80
{ background-color: rgba(230,0,126,0.8) }
.bgpink90
{ background-color: rgba(230,0,126,0.9) }

.fgpink, .afgpink a, .afgpink p.normal a, .widget .button.fgpink
{ color: #e6007e; }

.borderpink
{ border-color: #e6007e; }

  .bgpink.bgdark
, .bgpink > .bgdark
, .bgpink.bghoverdark:hover
, .bgpink:hover > .bghoverdark, .bgdarkhoverpink:hover
{ background-color: #b80065; }

/* unilabs red */
  .bgred
, .bgred.bghoverlight:hover
, .bgred:hover .bghoverlight, .bghoverred:hover
{ background-color: #EC4126; }

.bgred80
{ background-color: rgba(236,65,38,0.8) }
.bgred90
{ background-color: rgba(236,65,38,0.9) }

.fgred, .afgred a, .afgred p.normal a, .widget .button.fgred
{ color: #EC4126; }

.borderred
{ border-color: #EC4126; }

  .bgred.bgdark
, .bgred > .bgdark
, .bgred.bghoverdark:hover
, .bgred:hover > .bghoverdark, .bgdarkhoverred:hover
{ background-color: #bd3124; }

  .bgblue
, .bgblue.bghoverlight:hover
, .bgblue:hover .bghoverlight, .bghoverblue:hover
{ background-color: #00539c; }

.bgblue80
{ background-color: rgba(0,83,156,0.8) }
.bgblue90
{ background-color: rgba(0,83,156,0.9) }

.fgblue, .afgblue a, .afgblue p.normal a, .widget .button.fgblue
{ color: #00539c; }

.borderblue
{ border-color: #00539c; }

  .bgblue.bgdark
, .bgblue > .bgdark
, .bgblue.bghoverdark:hover
, .bgblue:hover > .bghoverdark, .bgdarkhoverblue:hover
{ background-color: #00427d; }

  .bggreyblue
, .bggreyblue.bghoverlight:hover
, .bggreyblue:hover .bghoverlight, .bghovergreyblue:hover
{ background-color: #407799; }

.bggreyblue80
{ background-color: rgba(64,119,153,0.8) }
.bggreyblue90
{ background-color: rgba(64,119,153,0.9) }

.fggreyblue, .afggreyblue a, .afggreyblue p.normal a, .widget .button.fggreyblue
{ color: #407799; }

.bordergreyblue
{ border-color: #407799; }

  .bggreyblue.bgdark
, .bggreyblue > .bgdark
, .bggreyblue.bghoverdark:hover
, .bggreyblue:hover > .bghoverdark, .bgdarkhovergreyblue:hover
{ background-color: #335f7a; }

  .bgorange
, .bgorange.bghoverlight:hover
, .bgorange:hover .bghoverlight, .bghoverorange:hover
{ background-color: #ed7703; }

.bgorange80
{ background-color: rgba(237,119,3,0.8) }
.bgorange90
{ background-color: rgba(237,119,3,0.9) }

.fgorange, .afgorange a, .afgorange p.normal a, .widget .button.fgorange
{ color: #ed7703; }

.borderorange
{ border-color: #ed7703; }

  .bgorange.bgdark
, .bgorange > .bgdark
, .bgorange.bghoverdark:hover
, .bgorange:hover > .bghoverdark, .bgdarkhoverorange:hover
{ background-color: #be5f02; }

  .bgyellow
, .bgyellow.bghoverlight:hover
, .bgyellow:hover .bghoverlight, .bghoveryellow:hover
{ background-color: #ecbe00; }

.bgyellow80
{ background-color: rgba(236,190,0,0.8) }
.bgyellow90
{ background-color: rgba(236,190,0,0.9) }

.fgyellow, .afgyellow a, .afgyellow p.normal a, .widget .button.fgyellow
{ color: #ecbe00; }

.borderyellow
{ border-color: #ecbe00; }

  .bgyellow.bgdark
, .bgyellow > .bgdark
, .bgyellow.bghoverdark:hover
, .bgyellow:hover > .bghoverdark, .bgdarkhoveryellow:hover
{ background-color: #bd9800; }

  .widget .button.bghovergreygreen:hover
, .widget .button.bghoverbrown:hover
, .widget .button.bghovergreen:hover
, .widget .button.bghoverviolet:hover
, .widget .button.bghovermagenta:hover
, .widget .button.bghoverpink:hover
, .widget .button.bghoverred:hover
, .widget .button.bghoverblue:hover
, .widget .button.bghovergreyblue:hover
, .widget .button.bghoverorange:hover
, .widget .button.bghoveryellow:hover
{
  color: #fff;
}

/* search
.wh-autocomplete-values
{
  z-index: 100;
  background: #fff;
  margin-top: -1px;
  width: 250px;
  border: 1px solid #ccc;
}
.wh-autocomplete-values .wh-menu-item
{
  padding: 2px 5px;
}
.wh-autocomplete-values .wh-menu-item:hover
{
  background-color: #81aa94;
  color: #fff;
}
*/

@media print
{
    header, #imageheader, #afterheader, #rightcol
  , #sharepage, footer, #mobilemenuholder, #globalmessage_holder
  {
    display: none;
  }
  .twocol > main
  {
    display: block;
    width: 100%;
  }
}
